import { WithChildren } from '@xylabs/react-shared'
import { ContextExProviderProps } from '@xyo-network/react-sdk'

import { BreadcrumbData } from '../../Breadcrumb'
import { BreadcrumbsContext } from './Context'
import { useBreadcrumbs } from './use'

export interface BreadcrumbProviderProps extends ContextExProviderProps, BreadcrumbData {}

export const BreadcrumbProvider: React.FC<WithChildren<BreadcrumbProviderProps>> = ({ required, name, to, href, children }) => {
  const { breadcrumbs = [], root = '/' } = useBreadcrumbs()

  const resolvedRoot = to ? `${root}${to}/` : root

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs: [...breadcrumbs, { href, name, to: resolvedRoot }], provided: true, root: resolvedRoot }}>
      {breadcrumbs.length ? children : required ? null : children}
    </BreadcrumbsContext.Provider>
  )
}
