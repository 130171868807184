import { ButtonGroup, TableCell, TableRow, TableRowProps } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { AppSettingsStorage, useAppSettings } from '@xyo-network/react-sdk'
import { useEffect } from 'react'

export const NavigationCollapsedTableRow: React.FC<TableRowProps> = (props) => {
  const { navigationCollapsed, changeNavigationCollapsed } = useAppSettings()

  const onToggleClick = () => {
    changeNavigationCollapsed?.(!navigationCollapsed)
  }

  useEffect(() => {
    const storage = new AppSettingsStorage('AppSettings')
    if (navigationCollapsed !== storage.navigationCollapsed) {
      changeNavigationCollapsed?.(storage.navigationCollapsed)
    }
  }, [changeNavigationCollapsed, navigationCollapsed])

  return (
    <TableRow {...props}>
      <TableCell>Navigation Collapsed</TableCell>
      <TableCell align="center">{navigationCollapsed?.toString()}</TableCell>
      <TableCell align="center">
        <ButtonGroup fullWidth>
          <ButtonEx variant="contained" size="small" onClick={onToggleClick}>
            Toggle
          </ButtonEx>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}
