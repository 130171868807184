import { alpha } from '@mui/material'
import { Chart as ChartJS, ChartData, ChartEvent, ChartType, LegendItem } from 'chart.js'
import { JSXElementConstructor, MouseEventHandler, useMemo, useRef } from 'react'
import { getElementAtEvent } from 'react-chartjs-2'

import { SchemaFilterEventBuilder } from '../../../shared'
import { useSchemaStatsFilter } from '../../contexts'
import { useChartColors, useChartData } from './hooks'
import { RemainingLabel, SchemaChartComponentProps } from './lib'

export interface SchemaChartProps {
  ChartComponent: JSXElementConstructor<SchemaChartComponentProps>
  label?: string
}

export const SchemaChart: React.FC<SchemaChartProps> = ({ ChartComponent, label }) => {
  const { schemaFilter, schemaFilterBgColor } = useSchemaStatsFilter()
  const chartRef = useRef<ChartJS<ChartType>>()

  const { dataLabelList, dataValueList } = useChartData(schemaFilter)
  const { colorsAlpha, colors } = useChartColors(schemaFilterBgColor)
  const chartData: ChartData<'doughnut' | 'bar'> = useMemo(
    () => ({
      datasets: [
        {
          backgroundColor: colorsAlpha(0.2),
          borderColor: colors,
          borderWidth: 2,
          data: dataValueList,
          hoverBorderWidth: 4,
          label,
        },
      ],
      labels: dataLabelList,
    }),
    [colors, colorsAlpha, dataLabelList, dataValueList, label],
  )

  const onChartClick: MouseEventHandler<HTMLCanvasElement> = (event) => {
    if (chartRef?.current) {
      const currentElement = getElementAtEvent(chartRef.current, event)[0]
      const { index } = currentElement ?? {}
      // Ensure the click occurred on a valid element in the chart
      if (index !== undefined) {
        const schema = dataLabelList[index].toLowerCase()

        if (schema === RemainingLabel.toLowerCase()) {
          const newEvent = SchemaFilterEventBuilder('up', 'browseRemaining')
          chartRef.current?.canvas.dispatchEvent(newEvent)
          return
        }

        const bgColor = alpha(colors[index], 0.5)
        const schemaFilterEvent = SchemaFilterEventBuilder(schema.toLocaleLowerCase(), 'schemaFilter')
        const schemaFilterBgColorEvent = SchemaFilterEventBuilder(bgColor, 'schemaFilterBgColor')

        chartRef.current?.canvas.dispatchEvent(schemaFilterEvent)
        chartRef.current?.canvas.dispatchEvent(schemaFilterBgColorEvent)
      }
    }
  }

  const onLegendItemClick = (_event: ChartEvent, legendItem: LegendItem) => {
    const newEvent = SchemaFilterEventBuilder(legendItem.text.toLowerCase(), 'legendItem')
    chartRef.current?.canvas.dispatchEvent(newEvent)
  }

  return (
    <ChartComponent
      chartData={chartData}
      chartRef={chartRef}
      onClick={onChartClick}
      onLegendItemClick={onLegendItemClick}
      schemaFilter={schemaFilter}
    />
  )
}
