import { WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { Route, Routes, RoutesProps } from 'react-router-dom'

import { AddressRoutes } from '../address'
import { ArchivistRoutes } from '../archivist'
import { BlockRoutes } from '../block'
import { PayloadRenderRoutes, PayloadRoutes } from '../payload'
import { SchemaRoutes } from '../schema'
import { BreadcrumbProvider } from '../shared'
import { HomePageDashboard, HomePageHeatMap, JsonPage, SettingsPage } from './pages'

export const RootRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name="XYO">
      <Routes {...props}>
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/activity" element={<HomePageHeatMap />} />
        <Route path="/json" element={<JsonPage />} />
        <Route path="/block/*" element={<BlockRoutes />} />
        <Route path="/payload/*" element={<PayloadRoutes />} />
        <Route path="/archivist/*" element={<ArchivistRoutes />} />
        <Route path="/schema/*" element={<SchemaRoutes />} />
        <Route path="/address/*" element={<AddressRoutes />} />
        <Route path="/:hash/*" element={<PayloadRenderRoutes />} />
        <Route path="/" element={<HomePageDashboard />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
