import { CardContent } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { PageCard, useNetwork, WebAppPage } from '@xyo-network/react-sdk'

import { DynamicBreadcrumbs } from '../../shared'

export const ArchivistListPage = () => {
  const { network } = useNetwork()

  return (
    <WebAppPage title="Archivists" breadcrumbs={<DynamicBreadcrumbs />}>
      <PageCard subheader={`The archivists on the ${network?.name} network that you can detect`} title="Archivist">
        <FlexCol>
          <CardContent>
            {network?.nodes ? (
              <FlexCol alignItems="flex-start">
                {network.nodes
                  .filter((node) => node.type === 'archivist')
                  .map((node) => (
                    <LinkEx key={node.slug} to={`/archivist/${node.slug}`}>
                      {node.slug}
                    </LinkEx>
                  ))}
              </FlexCol>
            ) : (
              <FlexRow marginY={16} busy></FlexRow>
            )}
          </CardContent>
        </FlexCol>
      </PageCard>
    </WebAppPage>
  )
}
