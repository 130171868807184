import { useAsyncEffect } from '@xylabs/react-async-effect'
import { TYPES } from '@xyo-network/node-core-types'
import { Payload } from '@xyo-network/payload'
import { PayloadHasher } from '@xyo-network/protocol'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { usePayloadFindFilter } from '../../hooks'
import { usePayloadList } from './use'

export const useNewPayloads = (schema?: string, address?: string, limit = 100, nameOrAddress = TYPES.PayloadDiviner.description) => {
  // TODO - offset support requires a type change to string in the query payload so we can use hashes
  const { offset, setLoading, setOffset, totalPayloads, updateTotalPayloads, resetList } = usePayloadList()

  const [newPayloads, setNewPayloads] = useState<Payload[]>()
  const [xyoError, setXyoError] = useState<Error>()
  const [newPayloadsState, setNewPayloadsState] = useState<Payload[]>()
  const [lastHash, setLastHash] = useState<string | undefined>(undefined)

  const filterProps = useMemo(() => ({ address, limit, schemas: schema ? [schema] : undefined }), [address, limit, schema])
  const filter = usePayloadFindFilter(filterProps)

  const [payloadDiviner] = useDivinerFromNode(nameOrAddress)

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (payloadDiviner && filter) {
        try {
          const payloads = await payloadDiviner.divine([filter])
          setNewPayloads(payloads)
          setXyoError(undefined)
        } catch (e) {
          setXyoError(e as Error)
          setNewPayloads(undefined)
        }
      }
    },
    [payloadDiviner, filter],
  )

  useEffect(() => {
    setNewPayloadsState(newPayloads)
  }, [newPayloads])

  /**
   * Cases to handle
   * 1. Total Payloads is empty and needs new payloads - done
   * 2. Total Payloads is filled and needs new payloads - tbd
   * 3. Total Payloads is filled and needs to be emptied before new payloads (schema change) - done
   */

  useEffect(() => {
    // reset on any schema change
    resetList?.()
    setNewPayloadsState(undefined)
    // reset offset when schema or archive changes
    setOffset?.(undefined)
  }, [address, schema, setOffset, resetList])

  useEffect(() => {
    if (xyoError || newPayloadsState) {
      setLoading?.(false)
    }
  }, [xyoError, setLoading, newPayloadsState])

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      const lastPayload = newPayloadsState?.at(-1)
      if (lastPayload) {
        setLastHash(await PayloadHasher.hashAsync(lastPayload))
      }
    },
    [newPayloadsState],
  )

  const newLastPayload = useCallback(() => {
    return offset && lastHash ? lastHash !== offset : undefined
  }, [lastHash, offset])

  useEffect(() => {
    if (newPayloadsState && totalPayloads?.length === 0) {
      updateTotalPayloads?.(newPayloadsState)
    }
  }, [newLastPayload, newPayloadsState, totalPayloads?.length, updateTotalPayloads])

  const fetchMorePayloads = () => {
    setLoading?.(true)
  }

  return { fetchMorePayloads, newPayloads: newPayloadsState, xyoError }
}
