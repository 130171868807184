import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Chip, IconButton } from '@mui/material'
import { FlexBoxProps, FlexRow } from '@xylabs/react-flexbox'

import { useSchemaStatsFilter, useSchemaStatsProcessor } from '../../contexts'

export const SchemaSetPagerFlex: React.FC<FlexBoxProps> = ({ ...props }) => {
  const { schemaFilterDispatch, schemaFilter } = useSchemaStatsFilter()
  const { offsetStart, offsetEnd, total } = useSchemaStatsProcessor()

  const buildSetLabels = () => {
    if ([offsetStart, offsetEnd, total].every((value) => value !== undefined)) {
      return `${offsetStart} - ${offsetEnd} of ${total}`
    } else {
      return ''
    }
  }
  return (
    <>
      {schemaFilter ? (
        <FlexRow justifyContent="center" flexWrap="wrap" columnGap={1} minHeight="40px" {...props}>
          <Chip onDelete={() => schemaFilterDispatch?.('clearSchemaFilter', 'click', 'true')} label={'Remove Filter'} />
        </FlexRow>
      ) : (
        <FlexRow justifyContent="center" flexWrap="wrap" columnGap={1} {...props}>
          <IconButton onClick={() => schemaFilterDispatch?.('browseRemaining', 'click', 'down')}>
            <ChevronLeftIcon />
          </IconButton>
          <Chip label={buildSetLabels()} />
          <IconButton onClick={() => schemaFilterDispatch?.('browseRemaining', 'click', 'up')}>
            <ChevronRightIcon />
          </IconButton>
        </FlexRow>
      )}
    </>
  )
}
