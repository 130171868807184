import { WithChildren } from '@xylabs/react-shared'

import { FavoriteAddressesProvider } from './contexts'
import { FavoriteArchivist } from './FavoriteArchivist'

export const ExplorerFavorites: React.FC<WithChildren> = ({ children }) => (
  <FavoriteAddressesProvider>
    <FavoriteArchivist>{children}</FavoriteArchivist>
  </FavoriteAddressesProvider>
)
