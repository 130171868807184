import { AddressPayload } from '@xyo-network/payload-plugins'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSelectedAddress = (addresses?: AddressPayload[], syncQueryParam = true) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressPayload | null>()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    if (syncQueryParam) {
      const addressFromParams = params.get('address')
      if (addresses && addresses.length !== 0) {
        const foundAddress = addresses.find(({ address }) => address === addressFromParams)
        setSelectedAddress(foundAddress ?? null)
      }
    }
  }, [addresses, params, syncQueryParam])

  const onChange = (value: AddressPayload | null) => {
    if (value !== selectedAddress) {
      setSelectedAddress?.(value)
      if (syncQueryParam) {
        setParams((params) => {
          params.set('address', value?.address ?? '')
          return params
        })
      }
    }
    if (value === null) {
      setParams((params) => {
        params.delete('address')
        return params
      })
      setSelectedAddress(null)
    }
  }

  return { onChange, selectedAddress, setSelectedAddress }
}
