import { alpha, useTheme } from '@mui/material'
import { useMemo } from 'react'

export const useChartColors = (colorOverride?: string) => {
  const theme = useTheme()
  const colors = useMemo(
    () => [
      colorOverride ? colorOverride : theme.palette.primary.main,
      colorOverride ? colorOverride : theme.palette.secondary.main,
      colorOverride ? colorOverride : theme.palette.error.main,
      colorOverride ? colorOverride : theme.palette.warning.main,
      colorOverride ? colorOverride : theme.palette.success.main,
      colorOverride ? colorOverride : theme.palette.info.main,
    ],
    [
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      colorOverride,
    ],
  )

  const colorsAlpha = (alphaValue: number) => colors.map((color) => alpha(color, alphaValue))

  return { colors, colorsAlpha }
}
