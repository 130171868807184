import { WithChildren } from '@xylabs/react-shared'
import { Payload } from '@xyo-network/payload'
import { PaginationNouns, useEvent } from '@xyo-network/react-sdk'
import { useCallback, useEffect, useState } from 'react'

import { PayloadListContext } from './Context'

type PayloadListProviderProps = WithChildren

export const PayloadListProvider: React.FC<PayloadListProviderProps> = ({ children }) => {
  const [totalPayloads, setTotalPayloads] = useState<Payload[] | undefined>([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState<string>()
  const [scrollToTop, setScrollTop] = useState(0)

  const [scrollRef] = useEvent<HTMLTableElement, PaginationNouns>((noun) => {
    if (noun === 'nextPage' && scrollRef.current) {
      setScrollTop((previous) => previous + 1)
      // bring back once we have pagination
      // setOffset((previous) => {
      //   const lastItem = totalPayloads?.at(-1)
      //   if (lastItem) {
      //     return PayloadWrapper.wrap(lastItem).hash
      //   }
      //   return previous
      // })
    }
  })

  const resetList = useCallback(() => {
    setTotalPayloads([])
    setCount(0)
    setOffset(undefined)
    setLoading(true)
  }, [])

  useEffect(() => {
    if (offset) {
      setLoading(true)
    }
  }, [offset])

  const updateTotalPayloads = (additionalPayloads?: Payload[]) => {
    if (additionalPayloads && additionalPayloads.length) {
      setTotalPayloads((previous) => {
        if (previous) {
          previous?.push(...additionalPayloads)
          setCount(previous?.length)
        }
        return previous
      })
    }
    return true
  }

  return (
    <PayloadListContext.Provider
      value={{
        count,
        loading,
        offset,
        provided: true,
        resetList,
        scrollRef,
        scrollToTop,
        setLoading,
        setOffset,
        totalPayloads,
        updateTotalPayloads,
      }}
    >
      {children}
    </PayloadListContext.Provider>
  )
}
