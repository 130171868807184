import { Chip, ChipProps, Fade } from '@mui/material'

import { useSchemaStatsFilter } from '../../contexts'

export const SchemaFilterChip: React.FC<ChipProps> = (props) => {
  const { schemaFilter, schemaFilterBgColor } = useSchemaStatsFilter()

  return (
    <Fade in={!!schemaFilter}>
      <Chip label={schemaFilter} sx={{ backgroundColor: schemaFilterBgColor }} {...props} />
    </Fade>
  )
}
