import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { Collapse, IconButton, IconButtonProps, styled, Typography } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { useState } from 'react'

import { useSchemaStatsFilter } from '../../../contexts'
import { SchemaFilterChip } from '../SchemaFilterChip'
import { FilterNameSelect } from './FilterNameSelect'
import { FilterRowFlex } from './FilterRowFlex'
import { FilterTypeSelect } from './FilterTypeSelect'
import { FilterValueAutoComplete } from './FilterValueAutoComplete'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'expand' })<ExpandMoreProps>(({ theme, expand }) => ({
  marginLeft: 'auto',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export const SchemaFilterFlex: React.FC<FlexBoxProps> = (props) => {
  const { schemaFilter, schemaFilterDispatch } = useSchemaStatsFilter()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <FlexCol alignItems="stretch" flexWrap="wrap" justifyContent="start" px={2} py={1} {...props}>
      <FlexRow columnGap={1}>
        <FilterAltRoundedIcon />
        <Typography variant="subtitle1" component="p">
          Filters
        </Typography>
        {schemaFilter ? <SchemaFilterChip onDelete={() => schemaFilterDispatch?.('clearSchemaFilter', 'click', 'true')} /> : null}
        {schemaFilter ? <Typography variant={'caption'}>You have 1 filter applied</Typography> : null}
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </FlexRow>
      <Collapse in={expanded}>
        <FilterRowFlex>
          <FlexRow minWidth="50%" columnGap={1}>
            <FilterNameSelect size="small" fullWidth />
            <FilterTypeSelect size="small" fullWidth />
          </FlexRow>
          <FilterValueAutoComplete />
        </FilterRowFlex>
      </Collapse>
    </FlexCol>
  )
}
