import { Divider } from '@mui/material'
import { FlexBoxProps, FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { SchemaProperty, useEvent } from '@xyo-network/react-sdk'
import { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import { useNavigate } from 'react-router-dom'

export interface SchemaLoadedViewerProps extends FlexBoxProps {
  schema: SchemaPayload
}

export const SchemaLoadedViewer: React.FC<SchemaLoadedViewerProps> = ({ schema, ...props }) => {
  const navigate = useNavigate()
  const [ref] = useEvent<HTMLDivElement>((noun, _verb, data) => {
    if (noun === 'schema' && data) {
      const parsedEvent = JSON.parse(data)
      const openInNewWindow = parsedEvent.openNewWindow
      const schema = parsedEvent.schema

      if (openInNewWindow) {
        const { pathname, search, origin } = window.location
        window.open(`${origin}${pathname}/${schema}${search}`, '_blank')
      }
      navigate(schema)
    }
  })

  return (
    <FlexCol ref={ref} alignItems="stretch" {...props}>
      <SchemaProperty value={schema.definition.$id} />
      <FlexRow component={Divider} marginY={2} />
    </FlexCol>
  )
}
