import { MenuItem, Select, SelectProps } from '@mui/material'

import { useRender } from '../../contexts'

export const RenderSelect: React.FC<SelectProps<string>> = (props) => {
  const { setRender, plugin, canRender } = useRender()

  return (
    <Select value={plugin.slug ?? ''} onChange={(event) => setRender?.(event.target.value)} {...props}>
      {canRender?.find((configToCheck) => configToCheck.slug === plugin.slug) ? null : (
        <MenuItem key={plugin.slug} value={plugin.slug}>
          {plugin.name}
        </MenuItem>
      )}
      {canRender?.map((config) => {
        return (
          <MenuItem key={config.slug} value={config.slug}>
            {config.name}
          </MenuItem>
        )
      })}
    </Select>
  )
}
