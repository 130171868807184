import { useActiveBoundWitness, useEvent } from '@xyo-network/react-sdk'
import { useNavigate } from 'react-router-dom'

import { PayloadDrawer, PayloadDrawerProps } from './PayloadDrawer'

export const PayloadDrawerWithRouting: React.FC<PayloadDrawerProps> = ({ children, ...props }) => {
  const navigate = useNavigate()
  const { activeBoundWitness } = useActiveBoundWitness()

  const [ref] = useEvent<HTMLDivElement>((noun, _verb, data) => {
    if (noun === 'payload' && data) {
      const payloadHash = activeBoundWitness?.payload_hashes.find((hash) => hash === data)
      if (payloadHash) {
        navigate(data)
      } else {
        console.error(`Unable to find payload with hash: ${data} in activeBoundWitness: ${JSON.stringify(activeBoundWitness, null, 2)}`)
      }
    }
  })

  return (
    <PayloadDrawer ref={ref} {...props}>
      {children}
    </PayloadDrawer>
  )
}
