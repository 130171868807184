import { Payload } from '@xyo-network/payload'

export type FavoriteAddressSchema = 'network.xyo.favorite.address'
export const FavoriteAddressSchema: FavoriteAddressSchema = 'network.xyo.favorite.address'

export type FavoriteAddressQuerySchema = 'network.xyo.favorite.address.query'
export const FavoriteAddressQuerySchema: FavoriteAddressQuerySchema = 'network.xyo.favorite.address.query'

export type FavoritePayloadBase = { name?: string }

export type FavoriteAddressPayload = Payload<FavoritePayloadBase & { address: string; schema: FavoriteAddressSchema }>
export const isFavoriteAddressPayload = (x?: Payload | null): x is FavoriteAddressPayload => x?.schema === FavoriteAddressSchema
