import { FlexBoxProps, FlexCol } from '@xylabs/react-flexbox'
import { ApiEmbedPluginCard, LoadResult, useListMode, usePayload } from '@xyo-network/react-sdk'

import { useRender } from '../../contexts'
import { PayloadActions } from './PayloadActions'

export const PayloadRender: React.FC<FlexBoxProps> = (props) => {
  const { payload, setPayload } = usePayload()
  const { plugin } = useRender()
  const { listMode } = useListMode()

  return (
    <FlexCol flexGrow={1} alignItems="stretch" justifyContent="start" rowGap={4} {...props}>
      <LoadResult searchResult={payload} notFound={payload === null}>
        <PayloadActions payload={payload ?? undefined} />
        <ApiEmbedPluginCard
          style={{ height: plugin.fillContainer ? '100%' : 'inherit' }}
          huriPayload={payload ?? ''}
          plugins={[plugin]}
          embedPluginConfig={{ listMode }}
          busyMinimum={500}
          busy={payload === undefined}
          onRefresh={() => setPayload?.(undefined)}
        />
      </LoadResult>
    </FlexCol>
  )
}
