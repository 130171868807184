import { SchemaStatsPayload } from '@xyo-network/diviner-schema-stats-model'
import { Dispatch, SetStateAction } from 'react'

import { ScaledStats, StatObject } from '../../lib'
import { StatsProcessor } from '../StatProcessor'
import { OffsetManager } from './OffsetManager'

export class ScaledStatsGenerator {
  allScaledCounts: StatObject[] = []
  scaledCounts: StatObject[] | undefined = []
  otherScaledCounts: StatObject[] | undefined = []
  otherScaledCountsSum: number | undefined = 0
  readonly offsetManager: OffsetManager

  private stats: SchemaStatsPayload | null
  private externalRefresh: Dispatch<SetStateAction<ScaledStats | undefined>>

  constructor(stats: SchemaStatsPayload | null, offsetIncrement: number, externalRefresh: Dispatch<SetStateAction<ScaledStats | undefined>>) {
    this.stats = stats
    this.externalRefresh = externalRefresh
    this.offsetManager = new OffsetManager(offsetIncrement, Object.keys(stats?.count ?? {}).length)
  }

  generateInitialCounts() {
    const counts = new StatsProcessor(this.stats).process()
    if (counts) {
      this.allScaledCounts = counts
      this.updateAllCountsAndRefresh()
    }
  }

  updateAllCountsAndRefresh() {
    this.updateScaledCounts()
    this.updateOtherCounts()
    this.refreshExternalConsumers()
  }

  changeOffset(direction: 'up' | 'down' = 'up') {
    this.offsetManager.updateOffset(direction)
    this.updateAllCountsAndRefresh()
  }

  filterBySchema(schemaFilter?: string) {
    if (schemaFilter) {
      this.scaledCounts = this.allScaledCounts.filter(({ schema }) => schemaFilter === schema)
      this.otherScaledCounts = []
      this.otherScaledCountsSum = 0
      this.refreshExternalConsumers()
    } else {
      this.updateAllCountsAndRefresh()
    }
  }

  private updateScaledCounts() {
    this.scaledCounts = this.allScaledCounts.slice(this.offsetManager.offset, this.offsetManager.offsetWindow)
  }

  private updateOtherCounts() {
    if (this.allScaledCounts.length - this.offsetManager.offset > 0) {
      this.otherScaledCounts = this.allScaledCounts.slice(this.offsetManager.offset + this.offsetManager.offsetIncrement, this.allScaledCounts.length)
    } else if (this.otherScaledCounts) {
      // at the end of the list so return the previous partial segment OR fallback to the last page
      const previousOffset = this.otherScaledCounts.length
        ? this.allScaledCounts.length - this.otherScaledCounts.length
        : this.offsetManager.offset - this.offsetManager.offsetIncrement
      this.otherScaledCounts = this.allScaledCounts.slice(previousOffset, this.allScaledCounts.length)
    }

    this.otherScaledCountsSum = this.otherScaledCounts?.reduce<number>((acc, { count }) => acc + count, 0)
  }

  private refreshExternalConsumers() {
    this.externalRefresh({
      allScaledCounts: this.allScaledCounts,
      offsetEnd: this.offsetManager.normalizedOffsetEnd(),
      offsetStart: this.offsetManager.normalizedOffsetStart(),
      otherScaledCounts: this.otherScaledCounts,
      otherScaledCountsSum: this.otherScaledCountsSum,
      scaledCounts: this.scaledCounts,
      total: this.allScaledCounts.length,
    })
  }
}
