import { CardContent, Theme, useMediaQuery } from '@mui/material'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SchemaPayload } from '@xyo-network/payload-plugins'
import { PageCard, WebAppPage } from '@xyo-network/react-sdk'
import { SchemaCache } from '@xyo-network/sdk'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { PayloadListCardWithProvider, PayloadListProvider } from '../../../payload'
import { DynamicBreadcrumbs } from '../../../shared'
import { SchemaJsonCard } from '../../components'

export const SchemaDetailsPage: React.FC = () => {
  const { schema } = useParams()
  const [payload, setPayload] = useState<SchemaPayload>()

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const flexDirection = isSmall ? 'column' : 'row'
  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      if (schema && !payload) {
        const entry = await SchemaCache.instance.get(schema)
        if (mounted()) {
          setPayload(entry?.payload)
        }
      }
    },
    [schema, payload],
  )

  return schema ? (
    <WebAppPage title={schema} breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
      <PageCard subheader={schema} title="Schema" sx={{ height: '100%' }}>
        <CardContent sx={{ alignItems: 'stretch', columnGap: 2, display: 'flex', flexDirection, height: '100%', overflowY: 'hidden', rowGap: 2 }}>
          <FlexGrowCol alignItems="stretch" justifyContent="center" flexBasis="50%" maxWidth={isSmall ? '100%' : '50%'}>
            <SchemaJsonCard sx={{ height: '100%' }} />
          </FlexGrowCol>
          <FlexGrowCol alignItems="stretch" justifyContent="center">
            <PayloadListProvider>
              <PayloadListCardWithProvider sx={{ height: '100%' }} />
            </PayloadListProvider>
          </FlexGrowCol>
        </CardContent>
      </PageCard>
    </WebAppPage>
  ) : null
}
