import { useEffect, useState } from 'react'

import { useSchemaStatsProcessor } from '../../../contexts'
import { RemainingLabel } from '../lib'

export const useChartData = (schemaFilter?: string | null) => {
  const [dataValueList, setDataValueList] = useState<number[]>([])
  const [dataLabelList, setDataLabelList] = useState<string[]>([])
  const { scaledCounts, otherScaledCountsSum } = useSchemaStatsProcessor()

  useEffect(() => {
    if (scaledCounts?.length === 0) {
      setDataValueList([])
      setDataLabelList([])
      return
    }
    if (scaledCounts) {
      setDataValueList(() => {
        return scaledCounts.reduce<number[]>((acc, { count }, index) => {
          acc.push(count)
          const validRemaining = otherScaledCountsSum !== undefined && otherScaledCountsSum > 0
          // on the last item and unfiltered, add the count for 'remaining'
          if (!schemaFilter && index === scaledCounts.length - 1 && validRemaining) {
            if (validRemaining) {
              acc.push(otherScaledCountsSum)
            }
          }
          return acc
        }, [])
      })
      setDataLabelList(() => {
        return scaledCounts.reduce<string[]>((acc, { schema }, index) => {
          acc.push(schema)
          const validRemaining = otherScaledCountsSum !== undefined && otherScaledCountsSum > 0
          // on the last item and unfiltered, add the count for 'remaining'
          if (!schemaFilter && index === scaledCounts.length - 1 && validRemaining) {
            acc.push(RemainingLabel)
          }
          return acc
        }, [])
      })
    }
  }, [scaledCounts, otherScaledCountsSum, schemaFilter])

  return { dataLabelList, dataValueList }
}
