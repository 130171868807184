import { CardContent, Table, TableBody, TableCell, TableHead, TableProps, TableRow } from '@mui/material'
import { MaxAccountsTableRow, PageCard, SeedPhraseTableRow, useAppSettings, useWalletContext, WebAppPage } from '@xyo-network/react-sdk'

import { DynamicBreadcrumbs } from '../../../shared'
import { DarkModeTableRow } from './DarkModeTableRow'
import { DeveloperModeTableRow } from './DeveloperModeTableRow'
import { NavigationCollapsedTableRow } from './NavigationCollapsedTableRow'
import { NavigationTypeTableRow } from './NavigationTypeTableRow'

export const SettingsTable: React.FC<TableProps> = (props) => {
  const { changeMaxAccounts, changeSeedPhrase, maxAccounts, navigationType, seedPhrase } = useAppSettings()
  const { activeAccountIndex } = useWalletContext()
  return (
    <Table {...props}>
      <TableHead>
        <TableRow>
          <TableCell width="100%">Property</TableCell>
          <TableCell align="center">Setting</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <DeveloperModeTableRow />
        <DarkModeTableRow />
        <NavigationTypeTableRow />
        {navigationType === 'sidebar' ? <NavigationCollapsedTableRow /> : null}
        <SeedPhraseTableRow changeSeedPhrase={changeSeedPhrase} seedPhrase={seedPhrase} />
        <MaxAccountsTableRow activeAccountIndex={activeAccountIndex} changeMaxAccounts={changeMaxAccounts} maxAccounts={maxAccounts} />
      </TableBody>
    </Table>
  )
}

export const SettingsPage: React.FC = () => {
  return (
    <WebAppPage title="Settings" breadcrumbs={<DynamicBreadcrumbs name="Settings" to="settings" />}>
      <PageCard title="Settings">
        <CardContent>
          <SettingsTable />
        </CardContent>
      </PageCard>
    </WebAppPage>
  )
}
