import { ButtonGroup, TableCell, TableRow, TableRowProps } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { useAppSettings } from '@xyo-network/react-sdk'

export const NavigationTypeTableRow: React.FC<TableRowProps> = (props) => {
  const { navigationType, changeNavigationType } = useAppSettings()

  const onToggleClick = () => {
    changeNavigationType?.(navigationType === 'menu' ? 'sidebar' : 'menu')
  }

  return (
    <TableRow {...props}>
      <TableCell>Navigation Type</TableCell>
      <TableCell align="center">{navigationType}</TableCell>
      <TableCell align="center">
        <ButtonGroup fullWidth>
          <ButtonEx variant="contained" size="small" onClick={onToggleClick}>
            Toggle
          </ButtonEx>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}
