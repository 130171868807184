import { WithChildren } from '@xylabs/react-shared'
import { useSchemaStats } from '@xyo-network/react-sdk'
import { useParams, useSearchParams } from 'react-router-dom'

import { SchemaStatsContext } from './Context'

interface SchemaStatsProviderProps extends WithChildren {
  address?: string
}

export const SchemaStatsProvider: React.FC<SchemaStatsProviderProps> = ({ children, address: addressProp }) => {
  const [params] = useSearchParams()
  const { address: addressParam } = useParams()
  const resolvedAddress = addressProp ?? addressParam ?? params.get('address')
  const [schemaStats, schemaError, refreshSchemaStats] = useSchemaStats(resolvedAddress ?? undefined)
  const rootSchemaStats = schemaStats?.find(({ name }) => !name)

  return (
    <SchemaStatsContext.Provider value={{ provided: true, schemaError, schemaStats: rootSchemaStats, setRefreshSchemaStats: refreshSchemaStats }}>
      {children}
    </SchemaStatsContext.Provider>
  )
}
