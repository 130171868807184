import { useTheme } from '@mui/material'
import { ArcElement, Chart as ChartJS, ChartData, Legend, Tooltip } from 'chart.js'
import { MutableRefObject } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { SchemaChartComponentProps } from './lib'

ChartJS.register(ArcElement, Tooltip, Legend)

export const SchemaDonutChart: React.FC<SchemaChartComponentProps> = ({ chartRef, chartData, onClick, onLegendItemClick, schemaFilter }) => {
  const theme = useTheme()
  const ref = chartRef as MutableRefObject<ChartJS<'doughnut'>>
  const data = chartData as ChartData<'doughnut'>
  return (
    <>
      {chartData ? (
        <Doughnut
          ref={ref}
          onClick={onClick}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                labels: { color: theme.palette.text.primary },
                onClick: onLegendItemClick,
                position: 'bottom',
              },
              title: {
                display: true,
                text: schemaFilter ? `Filtering by ${schemaFilter}` : 'Click to Filter by Schema',
              },
            },
          }}
          data={data}
          height="100%"
        />
      ) : null}
    </>
  )
}
