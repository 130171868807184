import { WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { Route, Routes, RoutesProps } from 'react-router-dom'

import { BreadcrumbProvider } from '../shared'
import { AddressHistoryPage } from './pages'

export const AddressRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name={'Address'} to="address">
      <Routes {...props}>
        <Route path="/" element={<AddressHistoryPage />} />
        <Route path="/:address" element={<AddressHistoryPage />} />
        <Route path="/:address/:boundwitness" element={<AddressHistoryPage />} />
        <Route path="/:address/:boundwitness/:hash" element={<AddressHistoryPage />} />
        <Route path="/:address/:boundwitness/:hash/:render" element={<AddressHistoryPage />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
