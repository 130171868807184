import { CardContent, CardContentProps, CardHeader, CardProps, LinearProgress } from '@mui/material'
import { CardEx } from '@xyo-network/react-sdk'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { useCallback, useState } from 'react'

import { useSchemaStats } from '../../contexts'
import { SchemaSetPagerFlex } from '../stats'
import { SchemaListMode } from './lib'
import { SchemaBarChart } from './SchemaBarChart'
import { SchemaChart } from './SchemaChart'
import { ChartModeButtonGroup } from './SchemaChartModeButtonGroup'
import { SchemaDonutChart } from './SchemaDonutChart'
import { SchemaList } from './SchemaList'

ChartJS.register(ArcElement, Tooltip, Legend)

export interface SchemaListCardProps extends CardProps {
  cardContentProps?: CardContentProps
}

export const SchemaListCard: React.FC<SchemaListCardProps> = ({ cardContentProps, ...props }) => {
  console.log(`SchemaListCard: ${cardContentProps}`)
  const { schemaStats } = useSchemaStats()
  const [schemaListMode, setSchemaListMode] = useState<SchemaListMode>('donut')

  const selectView = useCallback(() => {
    switch (schemaListMode) {
      case 'donut': {
        return <SchemaChart ChartComponent={SchemaDonutChart} label="Click to Filter" />
      }
      case 'bar': {
        return <SchemaChart ChartComponent={SchemaBarChart} />
      }
      case 'list': {
        return <SchemaList schemaStats={schemaStats} />
      }
    }
  }, [schemaListMode, schemaStats])

  console.log(`schemaStats: ${schemaStats}`)

  return (
    <CardEx {...props}>
      <CardHeader
        title="Schemas"
        action={<ChartModeButtonGroup setSchemaListMode={setSchemaListMode} schemaListMode={schemaListMode} />}
        sx={{ pl: 0 }}
      />
      {/* Intentionally moved outside of <CardContent> to allow charts to measure and fill containing element*/}
      <SchemaSetPagerFlex />
      <CardContent sx={{ padding: 0.25 }} {...cardContentProps}>
        {!schemaStats ? <LinearProgress /> : selectView()}
      </CardContent>
    </CardEx>
  )
}
