import { WithChildren } from '@xylabs/react-shared'
import { ActiveBoundWitnessProvider, RootStorageArchivist } from '@xyo-network/react-sdk'

import { PayloadDrawerWithRouting } from '../PayloadDrawer'

export const AddressHistoryProviders: React.FC<WithChildren> = ({ children }) => (
  <ActiveBoundWitnessProvider archivist={RootStorageArchivist}>
    <PayloadDrawerWithRouting>{children}</PayloadDrawerWithRouting>
  </ActiveBoundWitnessProvider>
)
