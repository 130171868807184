import { Button, ButtonGroup, ButtonGroupProps } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { SchemaListMode } from './lib'

interface ChartModeButtonGroupProps extends ButtonGroupProps {
  setSchemaListMode: Dispatch<SetStateAction<SchemaListMode>>
  schemaListMode?: SchemaListMode
}

export const ChartModeButtonGroup: React.FC<ChartModeButtonGroupProps> = ({ schemaListMode, setSchemaListMode, ...props }) => {
  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group" size="small" {...props}>
      <Button variant={schemaListMode === 'donut' ? 'contained' : 'outlined'} onClick={() => setSchemaListMode('donut')}>
        Donut
      </Button>
      <Button variant={schemaListMode === 'bar' ? 'contained' : 'outlined'} onClick={() => setSchemaListMode('bar')}>
        Bar
      </Button>
      <Button variant={schemaListMode === 'list' ? 'contained' : 'outlined'} onClick={() => setSchemaListMode('list')}>
        List
      </Button>
    </ButtonGroup>
  )
}
