import { useAsyncEffect } from '@xylabs/react-async-effect'
import { ArchivistInstance } from '@xyo-network/archivist'

import { useFavoriteAddresses } from '../contexts'
import { FavoriteAddressPayload, FavoriteAddressSchema } from '../lib'

export const useRestoreFavoriteAddresses = (favoritesArchivist?: ArchivistInstance) => {
  const { addFavoriteAddresses } = useFavoriteAddresses(true)

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (favoritesArchivist) {
        const payloads = (await favoritesArchivist.all?.()) as FavoriteAddressPayload[] | undefined
        const filtered = payloads?.filter((payload) => payload.schema === FavoriteAddressSchema)
        if (filtered) {
          addFavoriteAddresses?.(filtered)
        }
      }
    },
    [addFavoriteAddresses, favoritesArchivist],
  )
}
