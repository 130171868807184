import { useAsyncEffect } from '@xylabs/react-async-effect'
import { ManifestPayload, ManifestWrapper } from '@xyo-network/manifest'
import { MemoryNode } from '@xyo-network/node'
import { NodeProvider } from '@xyo-network/react-modules'
import { useNetwork, useWallet } from '@xyo-network/react-sdk'
import { PropsWithChildren, useState } from 'react'

import globalNodeManifest from './global-node-manifest.json'

export const GlobalNode: React.FC<PropsWithChildren> = ({ children }) => {
  const [globalNode, setGlobalNode] = useState<MemoryNode>()
  const [wallet] = useWallet()
  const { network } = useNetwork()

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (wallet && network) {
        try {
          const nodeWallet = await wallet.derivePath("0'")

          const nodeIndex = network.name === 'Main' ? 0 : network.name === 'Kerplunk' ? 1 : 2

          const manifest = new ManifestWrapper(globalNodeManifest as ManifestPayload, nodeWallet)
          const node = await manifest.loadNodeFromIndex(nodeIndex, {})
          console.log(`GlobalNode (should only run once) Nodes: ${node?.address}`)
          console.log(`GlobalNode Node: ${node.address}`)

          setGlobalNode(node)
        } catch (e) {
          const error = e as Error
          console.error(`Error creating global node: ${error.message}`)
          console.error(`Error creating global node: ${error.stack}`)
          throw Error(`Error creating global node: ${error.message}`)
        }
      }
    },
    [wallet, network],
  )

  return <NodeProvider node={globalNode}>{children}</NodeProvider>
}
