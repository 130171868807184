import { FlexBoxProps } from '@xylabs/react-flexbox'
import { CardContentEx, CardEx, ErrorRender, TypographyEx, useNetwork, WebAppPage } from '@xyo-network/react-sdk'
import { SchemaPayload } from '@xyo-network/schema-payload-plugin'

import { DynamicBreadcrumbs, PageCardWithAddresses, useAddressesWithCustomNames, useSelectedAddress } from '../../../shared'
import { SchemaLoadedList } from './SchemaLoadedList'

export interface SchemaLoadedViewerProps extends FlexBoxProps {
  schema: SchemaPayload
}

export const SchemaLoadedPage: React.FC = () => {
  const { network } = useNetwork()
  const [addresses, addressSpaceDivinerError] = useAddressesWithCustomNames()
  const { onChange, selectedAddress } = useSelectedAddress(addresses)

  return (
    <WebAppPage title="Schemas" breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
      <ErrorRender error={addressSpaceDivinerError}>
        <PageCardWithAddresses
          addresses={addresses}
          onAddressChange={onChange}
          selectedAddress={selectedAddress}
          subheader={<TypographyEx>Browse schemas seen on the {network?.name} network</TypographyEx>}
          title="Schemas"
        >
          <CardEx
            style={{
              backgroundColor: 'transparent',
              backgroundImage: 'none',
            }}
          >
            <CardContentEx variant="scrollable" sx={{ px: 0 }}>
              <SchemaLoadedList address={selectedAddress?.address} justifyContent="flex-start" alignItems="stretch" />
            </CardContentEx>
          </CardEx>
        </PageCardWithAddresses>
      </ErrorRender>
    </WebAppPage>
  )
}
