import { Autocomplete, ListItem, ListItemText, Popper, PopperProps, TextField, useTheme } from '@mui/material'
import numeral from 'numeral'
import { SyntheticEvent } from 'react'

import { StatObject, useSchemaStatsFilter, useSchemaStatsProcessor } from '../../../contexts'

interface FilterValueAutoCompleteProps {
  format?: string
}

export const FilterValueAutoComplete: React.FC<FilterValueAutoCompleteProps> = ({ format = '0[.]0a' }) => {
  const { allScaledCounts } = useSchemaStatsProcessor()
  const { schemaFilter, schemaFilterDispatch } = useSchemaStatsFilter()
  const theme = useTheme()

  const onChange = (_event: SyntheticEvent, value: StatObject | null) => {
    if (value === null) {
      schemaFilterDispatch?.('clearSchemaFilter', 'click', 'true')
    } else {
      // const newColor = RandomColorGenerator('50%')
      schemaFilterDispatch?.('schemaFilter', 'click', value?.schema)
      schemaFilterDispatch?.('schemaFilterBgColor', 'click', theme.palette.secondary.dark)
    }
  }

  if (allScaledCounts && allScaledCounts.length) {
    return (
      <Autocomplete
        fullWidth
        disablePortal
        getOptionLabel={({ schema }) => schema}
        options={allScaledCounts ?? []}
        size="small"
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderInput={(params) => <TextField {...params} label="Schema" />}
        value={allScaledCounts?.find(({ schema }) => schema === schemaFilter) ?? null}
        PopperComponent={AutoCompletePopper}
        onChange={onChange}
        renderOption={(props, { schema, count }) => (
          <ListItem {...props}>
            <ListItemText primary={`${schema} - ${numeral(count).format(format)}`} />
          </ListItem>
        )}
      />
    )
  } else {
    return <></>
  }
}

const AutoCompletePopper: React.FC<PopperProps> = (props) => {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />
}
