import { WithChildren } from '@xylabs/react-shared'
import { AppSettingsProvider, MapboxAccessTokenProvider } from '@xyo-network/react-sdk'

export const SettingsProviders: React.FC<WithChildren> = ({ children }) => {
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN
  if (!mapboxToken) {
    console.warn('Please provide a mapbox access token')
  }
  return (
    <AppSettingsProvider value={{}}>
      <MapboxAccessTokenProvider defaultAccessToken={mapboxToken}>{children}</MapboxAccessTokenProvider>
    </AppSettingsProvider>
  )
}
