import { assertEx } from '@xylabs/sdk-js'
import { AnimatedHeatMapLoaded, RootStorageArchivist, WebAppPage } from '@xyo-network/react-sdk'

export const HomePageHeatMap: React.FC = () => {
  return (
    <WebAppPage disableGutters title="Explore" paddingY={0} gap={0} spacing={0} variant="fixed">
      <AnimatedHeatMapLoaded
        accessToken={assertEx(process.env.REACT_APP_MAPBOX_TOKEN, 'Please provide a mapbox access token')}
        flexGrow={1}
        archivistNameOrAddress={RootStorageArchivist}
      />
    </WebAppPage>
  )
}
