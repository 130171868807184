import { UserEventsProvider, XyoUserEventHandler } from '@xylabs/react-pixel'
import { WithChildren } from '@xylabs/react-shared'
import { CollapsibleProvider, useAppSettings } from '@xyo-network/react-sdk'

export const UIProviders: React.FC<WithChildren> = ({ children }) => {
  const { navigationCollapsed } = useAppSettings()
  return (
    <UserEventsProvider userEvents={XyoUserEventHandler.get()}>
      <CollapsibleProvider defaultCollapse={navigationCollapsed}>{children}</CollapsibleProvider>
    </UserEventsProvider>
  )
}
