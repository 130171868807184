import { assertEx } from '@xylabs/sdk-js'
import { ArchivistInstance } from '@xyo-network/archivist'
import { PayloadHasher } from '@xyo-network/protocol'
import { EventNoun, EventVerb, FavoriteItemEvent } from '@xyo-network/react-sdk'

import { FavoriteAddressPayload, FavoriteAddressSchema } from './FavoriteAddress'

export class HandleAddressFavorite {
  private parsedEvent: FavoriteItemEvent
  private payload: FavoriteAddressPayload

  constructor(
    private favoritesArchivist: ArchivistInstance,
    private noun: EventNoun,
    private verb: EventVerb,
    data?: string,
    private onInsert?: (favoriteAddresses: FavoriteAddressPayload[]) => void,
    private onDelete?: (favoriteAddresses: FavoriteAddressPayload[]) => void,
  ) {
    if (!data) {
      console.error('favorite address event was empty')
    }
    this.parsedEvent = JSON.parse(data ?? '')

    assertEx(this.isAddressFavoriteEvent(), `expected favorite address event but got: noun: ${noun}, verb: ${verb}, parsedEvent: ${this.parsedEvent}`)

    this.payload = this.generateFavoriteAddressPayload(this.parsedEvent.favoriteValue as string, this.parsedEvent.name)
  }

  async applyFavoriteEvent() {
    await this.handleExistingFavorite(this.payload.address)

    if (this.parsedEvent.favorite) {
      try {
        await this.favoritesArchivist.insert([this.payload])
        this.onInsert?.([this.payload])
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const hash = await PayloadHasher.hashAsync(this.payload)
        await this.favoritesArchivist.delete?.([hash])
        this.onDelete?.([this.payload])
      } catch (e) {
        console.error(e)
      }
    }
  }

  private async existingFavorite(address: string) {
    const allFavorites = await this.favoritesArchivist.all?.()
    return allFavorites?.find((payload) => payload.schema === FavoriteAddressSchema && (payload as FavoriteAddressPayload).address === address)
  }

  private generateFavoriteAddressPayload(address: string, name?: string): FavoriteAddressPayload {
    return {
      address,
      name,
      schema: FavoriteAddressSchema,
    }
  }

  private async handleExistingFavorite(address: string) {
    const existingFavorite = await this.existingFavorite(address)

    if (existingFavorite) {
      const hash = await PayloadHasher.hashAsync(existingFavorite)
      await this.favoritesArchivist.delete?.([hash])
    }
  }

  private isAddressFavoriteEvent() {
    return this.noun == 'address' && this.verb === 'favorite' && this.parsedEvent.favoriteType === 'address' && this.parsedEvent.favoriteValue
  }
}
