import { ButtonGroup, TableCell, TableRow, TableRowProps } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { useAppSettings } from '@xyo-network/react-sdk'

export const DarkModeTableRow: React.FC<TableRowProps> = (props) => {
  const { darkMode, enableDarkMode } = useAppSettings()

  const onClick = () => {
    enableDarkMode?.(!darkMode)
  }

  return (
    <TableRow {...props}>
      <TableCell>Dark Mode</TableCell>
      <TableCell align="center">{darkMode ? 'True' : 'False'}</TableCell>
      <TableCell align="center">
        <ButtonGroup fullWidth>
          <ButtonEx variant="contained" size="small" onClick={onClick}>
            Toggle
          </ButtonEx>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}
