import { FlexBoxProps, FlexCol } from '@xylabs/react-flexbox'
import { useCollapsible } from '@xyo-network/react-sdk'

import { MenuSections } from './MenuSections'

export const ExploreMenuItems: React.FC<FlexBoxProps> = ({ ...props }) => {
  const { collapse } = useCollapsible()

  return (
    <FlexCol id="menu-sections-wrap" {...props}>
      <MenuSections collapse={collapse} />
    </FlexCol>
  )
}
