import { useTheme } from '@mui/material'
import { MenuSection } from '@xyo-network/react-sdk'

import { useMenuItems } from './hooks'

interface MenuSectionsProps {
  collapse?: boolean
}

export const MenuSections: React.FC<MenuSectionsProps> = ({ collapse }) => {
  const { MenuItemsChainData, MenuItemsMisc } = useMenuItems()
  const theme = useTheme()
  return (
    <>
      <MenuSection
        id="menu-section-top"
        title="Explore & Create"
        listItems={MenuItemsChainData}
        showTitle={!collapse}
        marginBottom={1}
        iconMenuTextSpacing={theme.spacing(2)}
      />
      <MenuSection
        id="menu-section-bottom"
        title="Settings & Analytics"
        listItems={MenuItemsMisc}
        showTitle={!collapse}
        iconMenuTextSpacing={theme.spacing(2)}
      />
    </>
  )
}
