import { useAsyncEffect } from '@xylabs/react-async-effect'
import type { WithChildren } from '@xylabs/react-shared'
import { ArchivistInstance } from '@xyo-network/archivist'
import { useProvidedNode } from '@xyo-network/react-modules'
import { GlobalNodeOffsetPath, useWallet, useWrapperAccount } from '@xyo-network/react-sdk'
import { useEffect, useState } from 'react'

import { FavoritesArchivist, ModuleOffsetPaths } from '../../lib'
import { useForwardFavoriteEvents, useRestoreFavoriteAddresses } from './hooks'
import { FavoriteArchivistBuilder } from './lib'

const favoritesOffsetPath = `${GlobalNodeOffsetPath}/${ModuleOffsetPaths[FavoritesArchivist]}`

export interface FavoriteArchivistInnerProps extends WithChildren {
  initialized?: boolean
}

export const FavoriteArchivistInner: React.FC<FavoriteArchivistInnerProps> = ({ children, initialized }) => {
  const [favoritesArchivist, setFavoritesArchivist] = useState<ArchivistInstance>()
  const [wallet] = useWallet()
  const [node] = useProvidedNode()
  const [wrapperAccount] = useWrapperAccount()

  useForwardFavoriteEvents(favoritesArchivist)
  useRestoreFavoriteAddresses(favoritesArchivist)

  const [favoriteArchivistBuilderWallet] = useWallet({ path: wallet ? favoritesOffsetPath : undefined, wallet })

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (initialized && favoriteArchivistBuilderWallet && wrapperAccount && node) {
        const favoritesArchivistBuilder = new FavoriteArchivistBuilder(favoriteArchivistBuilderWallet, node)
        await favoritesArchivistBuilder.build()
        await favoritesArchivistBuilder.safeAttachToNode()
        setFavoritesArchivist(favoritesArchivistBuilder.archivist)
      }
    },
    [initialized, node, wallet, favoriteArchivistBuilderWallet, wrapperAccount],
  )

  return <>{children}</>
}

export const FavoriteArchivist: React.FC<WithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const [wallet] = useWallet()
  const [node] = useProvidedNode()

  useEffect(() => {
    if (wallet && node && wallet) {
      setInitialized(true)
    }
  }, [wallet, node])

  return <FavoriteArchivistInner initialized={initialized}>{children}</FavoriteArchivistInner>
}
