import { assertEx } from '@xylabs/sdk-js'
import { AccountInstance } from '@xyo-network/account-model'
import { StorageArchivist, StorageArchivistConfigSchema } from '@xyo-network/archivist'
import { NodeInstance } from '@xyo-network/node'

import { FavoritesArchivist } from '../../../lib'

export class FavoriteArchivistBuilder {
  private config = { name: FavoritesArchivist, namespace: 'favorites-archivist', schema: StorageArchivistConfigSchema }
  private _archivist: StorageArchivist | undefined

  constructor(
    private account?: AccountInstance,
    private _node?: NodeInstance,
  ) {}

  get archivist(): StorageArchivist {
    return assertEx(this._archivist, 'this._archivist was not found')
  }

  get node(): NodeInstance {
    return assertEx(this._node, 'this._node was not found')
  }

  async build() {
    this._archivist = await StorageArchivist.create({
      account: this.account,
      config: this.config,
    })
  }

  async safeAttachToNode() {
    if (!(await this.node.attached())?.find((address) => address === this.archivist.address)) {
      this.node.register(this.archivist)
      await this.node.attach(this.archivist.address)
    }
  }
}
