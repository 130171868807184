import { CssBaseline } from '@mui/material'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { WithChildren } from '@xylabs/react-shared'
import {
  appThemeOptions,
  partialAppDarkThemeOptions,
  partialAppLightThemeOptions,
  useAppSettings,
  WebAppChrome,
  XyoFooter,
} from '@xyo-network/react-sdk'
import merge from 'lodash/merge'

import { ExploreMenuCollapse, PortalAppBar } from '../components'
import { useThemeOverrides } from '../hooks'

export const AppShell: React.FC<WithChildren> = ({ children }) => {
  const { darkMode, navigationType } = useAppSettings()
  const overrideOptions = useThemeOverrides()

  return (
    <InvertibleThemeProvider
      dark={darkMode}
      lightOptions={partialAppLightThemeOptions}
      darkOptions={partialAppDarkThemeOptions}
      options={merge(appThemeOptions, overrideOptions)}
    >
      <CssBaseline />
      <WebAppChrome
        navigationType={navigationType}
        menuItems={<ExploreMenuCollapse />}
        appName="XYO 2.1"
        appbar={<PortalAppBar />}
        footer={<XyoFooter dynamicHeight container="xl" />}
      >
        {children}
      </WebAppChrome>
    </InvertibleThemeProvider>
  )
}
