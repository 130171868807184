import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded'
import WidgetsIcon from '@mui/icons-material/Widgets'
import { IconButton, Paper, useMediaQuery, useTheme } from '@mui/material'
import {
  ApplicationAppBar,
  ApplicationAppBarProps,
  ContextToolbar,
  SearchBar,
  SystemToolbar,
  useAppSettings,
  useMenuItemsShared,
  WalletAccountSelectBar,
} from '@xyo-network/react-sdk'
import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { useFavoriteAddresses } from '../../../favorites'
import { MobileDialog } from './components'
import { ExploreMenuItems } from './ExploreMenuItems'

export interface PortalAppBar extends ApplicationAppBarProps {
  hideArchiveFilter?: boolean
}

export const PortalAppBar: React.FC<PortalAppBar> = (props) => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const location = useLocation()
  const { onMenuItemToggle } = useMenuItemsShared()
  const { maxAccounts, navigationType } = useAppSettings()
  const { addressNames } = useFavoriteAddresses()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)

  const nodeUrl = document.location.hostname.startsWith('beta.') ? 'https://beta.node.xyo.network' : 'https://node.xyo.network'
  const menuItems = isSmall || navigationType === 'menu' ? <ExploreMenuItems alignItems="stretch" /> : null

  return (
    <ApplicationAppBar
      responsive={false}
      contextToolbar={<ContextToolbar version />}
      systemToolbar={
        <SystemToolbar
          menuItems={menuItems}
          hideNetworkSelect={isSmall}
          networkSelectProps={{ responsive: isSmall }}
          darkModeButton
          precedingChildren={
            isSmall ? (
              <>
                <IconButton onClick={() => setOpen(true)}>
                  <SettingsApplicationsRoundedIcon htmlColor="white" fontSize="large" />
                </IconButton>
                <MobileDialog addressNames={addressNames} maxAccounts={maxAccounts} open={open} setOpen={setOpen} />
              </>
            ) : (
              <Paper variant="elevation" sx={{ mr: 1 }}>
                <WalletAccountSelectBar
                  iconOnly={isSmall}
                  addressNames={addressNames}
                  icons
                  maxAccounts={maxAccounts}
                  showFavorite
                  size="small"
                  sx={{ minWidth: 'calc(100vw / 4)' }}
                />
              </Paper>
            )
          }
          onMenuToggle={onMenuItemToggle}
        >
          <IconButton color="inherit" href={nodeUrl} target="_blank">
            <WidgetsIcon />
          </IconButton>
        </SystemToolbar>
      }
      {...props}
    >
      <SearchBar
        flexGrow={1}
        onSearch={(term) => {
          term ? navigate(`/${term.trim()}?search=true`) : null
        }}
        defaultValue={params.get('search') ? location.pathname.replace('/', '') : ''}
      />
    </ApplicationAppBar>
  )
}
