export class OffsetManager {
  // 0-based position to start slicing from
  offset: number
  // number of items to slice
  readonly offsetIncrement: number
  readonly total: number

  constructor(offsetIncrement: number, total: number) {
    this.offset = 0
    this.offsetIncrement = offsetIncrement
    this.total = total
  }

  get totalSets() {
    return Math.round(this.total / this.offsetIncrement)
  }

  get offsetWindow() {
    return this.offset + this.offsetIncrement
  }

  updateOffset(direction: 'up' | 'down' = 'up') {
    const change = direction === 'up' ? this.offsetIncrement : -Math.abs(this.offsetIncrement)
    let newOffset
    if (direction === 'down' && this.offset + change < 0) {
      newOffset = 0
    } else if (direction === 'up' && this.offset + change > this.total) {
      newOffset = this.offset
    } else {
      newOffset = this.offset + change
    }
    this.offset = newOffset
  }

  // Convenience methods to go from 0-based internal-facing to 1-based external-facing in ui
  normalizedOffsetStart(offset = this.offset) {
    return offset > this.total ? this.total : offset + 1
  }

  normalizedOffsetEnd(offset = this.offset) {
    if (offset === this.offsetIncrement || offset === 0) {
      return offset + this.offsetIncrement
    }
    if (offset + this.offsetIncrement > this.total) {
      return this.total
    } else {
      return offset + this.offsetIncrement
    }
  }
}
