import { FormControl, Grid, InputLabel } from '@mui/material'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { ButtonEx } from '@xylabs/react-button'
import { Payload } from '@xyo-network/payload'
import { PayloadHasher } from '@xyo-network/protocol'
import { useState } from 'react'

import { clonePayloadRoute, createSchemaRoute } from '../../../../lib'
import { useRender } from '../../contexts'
import { usePayloadActionUrls } from '../../hooks'
import { ListModeSelect } from './ListModeSelect'
import { RenderSelect } from './Select'

const renderSelectId = 'render-select-id'
const renderSelectLabel = 'Renderer'

const listModeSelectId = 'listmode-select-id'
const listModeSelectLabel = 'List Mode'

const navigateToNodeUrl = (nodeWebSiteUrl?: string) => {
  if (nodeWebSiteUrl) {
    const newWindow = window.open(nodeWebSiteUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null
    }
  } else {
    console.warn('Tried to navigate to node url but none was provided')
  }
}

const PayloadActions: React.FC<{ payload?: Payload }> = ({ payload }) => {
  const [hash, setHash] = useState<string | undefined>(undefined)
  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (payload) {
        setHash(await PayloadHasher.hashAsync(payload))
      }
    },
    [payload],
  )
  const { nodeWebSiteUrl: nodeUrlClonePayload } = usePayloadActionUrls(payload, `${clonePayloadRoute}/${hash}`, hash)
  const { nodeWebSiteUrl: nodeUrlCreateSchema } = usePayloadActionUrls(payload, createSchemaRoute, hash)

  const { plugin } = useRender()

  return (
    <Grid container alignItems="stretch" justifyContent="space-between" flexDirection="row">
      <Grid padding={0.5} item display="flex" columnGap={1}>
        <FormControl>
          <InputLabel id={renderSelectId}>{renderSelectLabel}</InputLabel>
          <RenderSelect size="small" label={renderSelectLabel} labelId={renderSelectId} />
        </FormControl>
        {(plugin?.components?.box?.listModes?.length ?? 0) > 1 ? (
          <FormControl>
            <InputLabel id={listModeSelectId}>{listModeSelectLabel}</InputLabel>
            <ListModeSelect size="small" label={listModeSelectLabel} labelId={listModeSelectId} />
          </FormControl>
        ) : null}
      </Grid>
      <Grid padding={0.5} item flexDirection="row" display="flex" columnGap={1}>
        <ButtonEx color="secondary" variant="contained" size="small" onClick={() => navigateToNodeUrl(nodeUrlCreateSchema)}>
          Create Schema
        </ButtonEx>
        <ButtonEx color="secondary" variant="contained" size="small" onClick={() => navigateToNodeUrl(nodeUrlClonePayload)}>
          Clone
        </ButtonEx>
      </Grid>
    </Grid>
  )
}

export { PayloadActions }
