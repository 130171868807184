import { Alert, AlertTitle, Card, CardHeader, CardProps, CircularProgress, Typography } from '@mui/material'
import { useAsyncEffect } from '@xylabs/react-async-effect'
import { FlexCol, FlexGrowCol } from '@xylabs/react-flexbox'
import { CardContentEx, ErrorRender } from '@xyo-network/react-sdk'
import { SchemaCache } from '@xyo-network/schema-cache'
import { SchemaPayload } from '@xyo-network/schema-payload-plugin'
import { Suspense, useState } from 'react'
import { VscWarning } from 'react-icons/vsc'
import { useParams } from 'react-router-dom'

import { SchemaLoadedJsonViewer } from '../SchemaJsonViewer'

export const SchemaJsonCard: React.FC<CardProps> = ({ ...props }) => {
  const { schema } = useParams()

  const [payload, setPayload] = useState<SchemaPayload | null>()
  const [error, setError] = useState<Error>()

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      if (schema) {
        try {
          const entry = await SchemaCache.instance.get(schema)
          if (mounted()) {
            setPayload(entry === null ? null : entry?.payload)
          }
          setError(undefined)
        } catch (e) {
          setError(e as Error)
          setPayload(null)
        }
      }
    },
    [schema],
  )

  return (
    <Card {...props}>
      <CardHeader title="JSON Viewer" />
      <CardContentEx variant="scrollable" style={{ height: '100%', justifyContent: 'start' }}>
        <ErrorRender error={error}>
          {payload === undefined ? (
            <FlexGrowCol minHeight="100%">
              <CircularProgress />
            </FlexGrowCol>
          ) : payload != null ? (
            <Suspense fallback={<FlexCol />}>{<SchemaLoadedJsonViewer src={payload} />}</Suspense>
          ) : (
            <Alert icon={<VscWarning fontSize="inherit" />} severity="warning">
              <AlertTitle>No Official Definition Located</AlertTitle>
              <Typography>
                Uh Oh! We searched <span style={{ fontFamily: 'monospace' }}>{schema?.split('.').slice(0, 2).reverse().join('.')}</span> for an
                official definition payload for this schema: <span style={{ fontFamily: 'monospace' }}>{schema}</span>
              </Typography>
            </Alert>
          )}
        </ErrorRender>
      </CardContentEx>
    </Card>
  )
}
