import { useAsyncEffect } from '@xylabs/react-async-effect'
import { BoundWitnessStatsQuerySchema } from '@xyo-network/diviner-boundwitness-stats-model'
import { PayloadStatsPayload, PayloadStatsQuerySchema } from '@xyo-network/diviner-payload-stats-model'
import { Payload, PayloadBuilder } from '@xyo-network/payload'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

type SupportedSchemas = BoundWitnessStatsQuerySchema | PayloadStatsQuerySchema
type SupportedDiviners = 'PayloadStatsDiviner' | 'BoundWitnessStatsDiviner'

export const useCount = (
  schema: SupportedSchemas,
  diviner: SupportedDiviners,
  address?: string,
): [number | null | undefined, Error | undefined, Dispatch<SetStateAction<number>>] => {
  const [count, setCount] = useState<number | null>()
  const [query, setQuery] = useState<Payload>()
  const [refresh, setRefresh] = useState(1)
  const [error, setError] = useState<Error>()

  const [divinerModule, divinerModuleError] = useDivinerFromNode(diviner)

  useEffect(() => {
    if (address) {
      setQuery(new PayloadBuilder({ schema }).fields({ address }).build())
    } else {
      setQuery(new PayloadBuilder({ schema }).build())
    }
  }, [address, refresh, schema])

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      if (divinerModule) {
        try {
          const resultPayload = (await divinerModule.divine(query ? [query] : undefined)).pop() as PayloadStatsPayload
          if (mounted()) {
            setCount(resultPayload?.count)
          }
        } catch (ex) {
          if (mounted()) {
            setError(ex as Error)
          }
        }
      }
    },
    [divinerModule, refresh, query],
  )

  return [count, error ?? divinerModuleError, setRefresh]
}
