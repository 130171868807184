import { SchemaStatsPayload } from '@xyo-network/diviner-schema-stats-model'

import { StatObject } from '../../lib'
import { Curve, DefaultFontSizeRange, DefaultOpacityRange } from './constants'
import { scaleValue } from './scaleValues'

export class StatsProcessor {
  public countsArray: StatObject[] = []
  private _stats
  private counts
  private minMaxCounts: number[] = []

  constructor(stats?: SchemaStatsPayload | null) {
    this._stats = stats
    if (stats?.count) {
      this.counts = stats?.count
    }
    return this
  }

  /**
   * Convenience method to process stats
   */
  public process() {
    if (this._stats === null) {
      return null
    } else {
      this.toArray()?.sortByCount()
      return this.countsArray
    }
  }

  public toArray() {
    if (this.counts) {
      this.countsArray = Object.entries(this.counts).reduce(
        (previous, [schema, count], index) => {
          // Save an iteration by simultaneously finding the min and max counts
          this.minMaxCounts[0] = this.minMaxCounts[0] < count ? this.minMaxCounts[0] : count
          this.minMaxCounts[1] = this.minMaxCounts[1] > count ? this.minMaxCounts[1] : count

          previous[index] = {
            count,
            schema,
          }
          return previous
        },
        [] as { schema: string; count: number }[],
      )

      return this
    } else {
      throw Error('tried to convert to Array before setting counts')
    }
  }

  public sortByCount() {
    this.countsArray.sort((a, b) => b.count - a.count)
    return this
  }

  /** deprecated */
  private aggregate() {
    if (this.countsArray) {
      if (this.countsArray.length > 10) {
        const top10 = this.countsArray.slice(0, 10)
        const others = this.countsArray.slice(11, this.countsArray.length)

        const aggregatedOthers = others.reduce(
          (previous, { count }) => {
            previous.count = previous.count + count
            return previous
          },
          { count: 0, schema: 'others' },
        )

        this.countsArray = [...top10, aggregatedOthers]

        return this
      } else {
        return this
      }
    } else {
      console.warn('tried to aggregate counts before converting to an array')
    }
  }

  /** deprecated */
  private scaleCounts() {
    this.countsArray = this.countsArray.map(({ schema, count }) => {
      const { scaledItem: scaledFontSize } = scaleValue(count, this.minMaxCounts, DefaultFontSizeRange, Curve)
      const { scaledItem: scaledOpacity } = scaleValue(count, this.minMaxCounts, DefaultOpacityRange, Curve)
      return {
        count,
        scaledFontSize,
        scaledOpacity,
        schema,
      }
    })

    return this
  }
}
