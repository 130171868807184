import ErrorIcon from '@mui/icons-material/Error'
import { Chip } from '@mui/material'
import { FlexBoxProps, FlexRow } from '@xylabs/react-flexbox'
import numeral from 'numeral'
import { useNavigate } from 'react-router-dom'

interface ArchivistStatsProps extends FlexBoxProps {
  format?: string
  blockCount?: number | null
  blockCountError?: string
  payloadCount?: number | null
  payloadCountError?: string
  schemaStats?: number | null
  schemaStatsError?: Error
}

export const ArchivistStatsFlexBox: React.FC<ArchivistStatsProps> = ({
  format = '0[.]0a',
  blockCount,
  payloadCount,
  payloadCountError,
  blockCountError,
  schemaStats,
  schemaStatsError,
  ...props
}) => {
  const navigate = useNavigate()

  return (
    <FlexRow {...props}>
      <Chip
        variant="outlined"
        icon={blockCountError ? <ErrorIcon titleAccess={blockCountError} /> : undefined}
        size="medium"
        label={`Blocks - ${numeral(blockCount).format(format)}`}
        onClick={() => navigate('/block')}
      />
      <Chip
        variant="outlined"
        icon={payloadCountError ? <ErrorIcon titleAccess={payloadCountError} /> : undefined}
        size="medium"
        label={`Payloads - ${numeral(payloadCount).format(format)}`}
        onClick={() => navigate('/payload')}
      />
      <Chip
        variant="outlined"
        icon={schemaStatsError ? <ErrorIcon titleAccess={schemaStatsError.message} /> : undefined}
        size="medium"
        label={`Schemas - ${numeral(schemaStats).format(format)}`}
        onClick={() => navigate('/schema')}
      />
    </FlexRow>
  )
}
