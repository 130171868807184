import { useArchivistFromNode } from '@xyo-network/react-modules'
import { ErrorRender, PayloadProvider, RootStorageArchivist, WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { useEffect, useState } from 'react'
import { Route, Routes, RoutesProps, useParams } from 'react-router-dom'

import { BreadcrumbProvider } from '../../shared'
import { PayloadRenderWithRouteProvider } from '../components'
import { PayloadRenderPage } from '../pages'

export const PayloadRenderRoutes: React.FC<RoutesProps> = (props) => {
  const { hash } = useParams()
  const [error, setError] = useState<Error>()
  const [archivist, archivistError] = useArchivistFromNode(RootStorageArchivist)

  useEffect(() => {
    setError(archivistError)
  }, [archivistError])

  return (
    <PayloadProvider archivist={archivist} hash={hash}>
      <ErrorRender error={error} onCancel={() => setError(undefined)}>
        <PayloadRenderWithRouteProvider>
          <BreadcrumbProvider name={hash === undefined ? '[missing search value]' : hash} to={hash}>
            <Routes {...props}>
              <Route path="/" element={<PayloadRenderPage />} />
              <Route path="/:render/:pointerResultHash/:pointerRender" element={<PayloadRenderPage />} />
              <Route path="/:render/*" element={<PayloadRenderPage />} />
              <Route path="*" element={<WebAppNotFoundPage />} />
            </Routes>
          </BreadcrumbProvider>
        </PayloadRenderWithRouteProvider>
      </ErrorRender>
    </PayloadProvider>
  )
}
