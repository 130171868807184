import { Payload } from '@xyo-network/protocol'
import { useArchivistFromNode, useArchivistGet } from '@xyo-network/react-modules'
import { LoadResult, PayloadDetails } from '@xyo-network/react-sdk'
import { useParams } from 'react-router-dom'

interface PayloadPointerRendererProps {
  payload?: Payload
}

export const PayloadPointerRenderer: React.FC<PayloadPointerRendererProps> = () => {
  const { hash } = useParams()
  const [archivist] = useArchivistFromNode()
  const [payloads, error] = useArchivistGet(archivist, hash ? [hash] : [])

  const payload = payloads?.pop()

  return (
    <LoadResult searchResult={payload} notFound={payload === null} apiError={error}>
      <PayloadDetails payload={payload ?? undefined} />
    </LoadResult>
  )
}
