import { WithChildren } from '@xylabs/react-shared'
import { generateMnemonic, wordlists } from '@xyo-network/bip39'
import { useAppSettings, useWallet, WalletProvider, WalletProviderProps } from '@xyo-network/react-sdk'

export const Wallet: React.FC<WithChildren<WalletProviderProps>> = ({ children, ...props }) => {
  const { seedPhrase } = useAppSettings()

  const [wallet] = useWallet({ mnemonic: seedPhrase ?? generateMnemonic(wordlists.english, 256) })

  console.log(`Wallet.address: ${wallet?.address}`)
  console.log(`Wallet.seedPhrase: ${seedPhrase}`)

  return (
    <WalletProvider rootWallet={wallet} {...props}>
      {children}
    </WalletProvider>
  )
}
