import { assertEx } from '@xylabs/sdk-js'
// eslint-disable-next-line import/no-internal-modules
import { createRoot } from 'react-dom/client'

import { App } from './App'
import * as serviceWorker from './serviceWorker'

const root = createRoot(assertEx(document.getElementById('root')))

root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
