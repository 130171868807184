import { Fbq, Gtag } from '@xylabs/react-pixel'
import { AbstractModule } from '@xyo-network/module'
import { ErrorReporterProvider, NetworkRouteProvider, PixelProvider, resolveNetworkFromHostName } from '@xyo-network/react-sdk'
import { SchemaCache } from '@xyo-network/schema-cache'
import { BrowserRouter } from 'react-router-dom'
import Rollbar from 'rollbar'

import { GlobalNode } from './GlobalNode'
import { getApiDomain } from './lib'
import { AppShell, ExplorerFavorites, RootRoutes, SeedPhrase, SettingsProviders, UIProviders, Wallet } from './modules'

const getRollbarEnvironment = () => {
  switch (document.location.hostname) {
    case 'explore.xyo.network':
      return 'production'
    case 'beta.explore.xyo.network':
      return 'beta'
    default:
      return 'development'
  }
}

AbstractModule.defaultLogger = process.env.REACT_APP_DEBUG_MODULES?.toLowerCase() === 'true' ? console : undefined

export const rollbar = new Rollbar({
  accessToken: 'de72a8fbda514e84a08e06e0d0fbbe5b',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: getRollbarEnvironment(),
})

Fbq.init('1653480428329003')
Gtag.init('G-795QBPW744', 'AW-989965544', ['explore.xyo.network'])

const defaultProxy = 'https://beta.api.archivist.xyo.network/domain'
SchemaCache.instance.proxy = getApiDomain().startsWith('http://localhost') ? defaultProxy : undefined

export const App: React.FC = () => {
  return (
    <ErrorReporterProvider rollbar={rollbar}>
      <PixelProvider id="explore.xyo.network">
        <SettingsProviders>
          <SeedPhrase>
            <Wallet>
              <BrowserRouter>
                <NetworkRouteProvider defaultNetworkName={resolveNetworkFromHostName('Kerplunk', 'explore')}>
                  <GlobalNode>
                    <ExplorerFavorites>
                      <UIProviders>
                        <AppShell>
                          <RootRoutes />
                        </AppShell>
                      </UIProviders>
                    </ExplorerFavorites>
                  </GlobalNode>
                </NetworkRouteProvider>
              </BrowserRouter>
            </Wallet>
          </SeedPhrase>
        </SettingsProviders>
      </PixelProvider>
    </ErrorReporterProvider>
  )
}
