import { WithChildren } from '@xylabs/react-shared'
import { useArchivistFromNode } from '@xyo-network/react-modules'
import { HashSelectionHistoryProvider, NestedBoundWitnessesProvider, RootStorageArchivist } from '@xyo-network/react-sdk'

export type HashSelectionHistoryArchivistProps = WithChildren

export const HashSelectionHistoryArchivist: React.FC<HashSelectionHistoryArchivistProps> = ({ children }) => {
  const [archivist] = useArchivistFromNode(RootStorageArchivist)
  return (
    <HashSelectionHistoryProvider archivist={archivist ?? undefined}>
      <NestedBoundWitnessesProvider>{children}</NestedBoundWitnessesProvider>
    </HashSelectionHistoryProvider>
  )
}
