import { WithChildren } from '@xylabs/react-shared'
import { RefObject } from 'react'

import { SchemaFilterStatsProvider, SchemaStatsProcessorProvider, SchemaStatsProvider, useSchemaStats } from '../../contexts'

interface StatsPageComponentProps {
  schemaFilterRef?: RefObject<HTMLDivElement>
}

const WithSchemaStatsProcessor: React.FC<WithChildren<StatsPageComponentProps>> = ({ children, schemaFilterRef }) => {
  const { schemaStats } = useSchemaStats()
  return (
    <SchemaStatsProcessorProvider schemaStats={schemaStats} schemaFilterRef={schemaFilterRef}>
      {children}
    </SchemaStatsProcessorProvider>
  )
}

export const SchemaChartProviders: React.FC<WithChildren<StatsPageComponentProps>> = ({ schemaFilterRef, children }) => {
  return (
    <SchemaStatsProvider>
      <WithSchemaStatsProcessor schemaFilterRef={schemaFilterRef}>
        <SchemaFilterStatsProvider schemaFilterRef={schemaFilterRef}>{children}</SchemaFilterStatsProvider>
      </WithSchemaStatsProcessor>
    </SchemaStatsProvider>
  )
}
