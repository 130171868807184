import { useAsyncEffect } from '@xylabs/react-async-effect'
import { BoundWitness } from '@xyo-network/boundwitness'
import { AddressHistoryQueryPayload, AddressHistoryQuerySchema } from '@xyo-network/diviner-address-history-model'
import { AddressPayload } from '@xyo-network/payload-plugins'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import compact from 'lodash/compact'
import { useState } from 'react'

export const useAddressBlocks = (address?: AddressPayload | null): [BoundWitness[] | undefined, Error | undefined] => {
  const [blocks, setBlocks] = useState<BoundWitness[]>()
  const [error, setError] = useState<Error>()
  const [addressHistoryDiviner, addressHistoryDivinerError] = useDivinerFromNode('AddressHistoryDiviner')

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      try {
        if (address === null) {
          setBlocks(undefined)
          return
        }
        if (address?.address) {
          const query: AddressHistoryQueryPayload = {
            address: [address.address],
            schema: AddressHistoryQuerySchema,
          }
          const bwList = compact(await addressHistoryDiviner?.divine([query])) as BoundWitness[]

          if (mounted()) {
            setBlocks(bwList)
            setError(undefined)
          }
        }
      } catch (ex) {
        if (mounted()) {
          setError(ex as Error)
          setBlocks(undefined)
        }
      }
    },
    [addressHistoryDiviner, address],
  )

  return [blocks, error ?? addressHistoryDivinerError]
}
