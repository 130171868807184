import { Payload } from '@xyo-network/protocol'
import { createPayloadRenderPlugin, PayloadRenderPlugin } from '@xyo-network/react-sdk'

import { PayloadPointerRenderer } from './components'

export const PayloadPointerRenderPlugin: PayloadRenderPlugin = {
  ...createPayloadRenderPlugin({
    canRender: (payload?: Payload) => {
      return payload?.schema === 'network.xyo.payload.pointer'
    },
    components: {
      box: {
        details: PayloadPointerRenderer,
      },
    },
    name: 'Payload Pointer',
  }),
}
