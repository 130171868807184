import { ListModeProvider } from '@xyo-network/react-sdk'

import { PayloadRender, PayloadRenderWithRouteProvider } from '../../../payload'

export const RenderPayload = () => {
  return (
    <PayloadRenderWithRouteProvider>
      <ListModeProvider>
        <PayloadRender minWidth="50vw" />
      </ListModeProvider>
    </PayloadRenderWithRouteProvider>
  )
}
