import { AutocompleteProps, CircularProgress, MenuItem, TextField } from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import { Identicon } from '@xylabs/react-identicon'
import { AddressPayload } from '@xyo-network/payload-plugins'
import { AddressRenderRowBox } from '@xyo-network/react-sdk'
import { MouseEvent, useState } from 'react'

import { AddressWithFavorite } from '../../../types'
import { AddressesListbox } from '../AddressesListBox'
import { AddressStatsChipOnHover } from '../AddressStatsChips'

type ModifiedAutoCompleteProps = Omit<AutocompleteProps<AddressWithFavorite, false, false, false>, 'options'>

export const AddressAutoCompleteConstantProps = {
  blurOnSelect: true,
  getOptionLabel: (option: AddressPayload) => option.name ?? option.address,
  handleHomeEndKeys: true,
  id: 'address-list-autocomplete',
  loadingText: 'Loading all addresses (sit tight)',
  noOptionsText: 'Unable to find addresses',
  selectOnFocus: true,
  size: 'small' as ModifiedAutoCompleteProps['size'],
}

export const useAddressAutoCompleteProps = (addresses?: AddressWithFavorite[], value?: AddressWithFavorite | null) => {
  const loading = addresses?.length === 0
  const [inputValue, setInputValue] = useState<string>('')
  const [open, setOpen] = useState(false)

  const isOptionEqualToValue: ModifiedAutoCompleteProps['isOptionEqualToValue'] = (option, value) => {
    if (option.address.includes(value.address)) return true
    if (option.name && value.name) return option.name.includes(value.name)
    return false
  }

  const renderInput: ModifiedAutoCompleteProps['renderInput'] = (params) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
          startAdornment: (
            <FlexRow mx={1}>
              <Identicon size={16} value={value?.address} />
            </FlexRow>
          ),
        }}
        label="Selected Address"
        onClick={() => setOpen(!open)}
      />
    )
  }

  const renderOption: ModifiedAutoCompleteProps['renderOption'] = (props, option) => {
    return (
      <MenuItem
        {...props}
        onTouchStart={(event) => {
          setOpen(false)
          props.onTouchStart?.(event)
          props.onClick?.(event as unknown as MouseEvent<HTMLLIElement>)
        }}
        onClick={(event) => {
          setOpen(false)
          props.onClick?.(event)
        }}
      >
        <AddressRenderRowBox address={option.address} favorite={option.favorite} name={option.name} icons showFavorite>
          <AddressStatsChipOnHover address={option.address} />
        </AddressRenderRowBox>
      </MenuItem>
    )
  }

  const onInputChange: ModifiedAutoCompleteProps['onInputChange'] = (_, newInputValue) => {
    setInputValue(newInputValue)
  }

  return {
    autoCompleteProps: {
      ...AddressAutoCompleteConstantProps,
      ListboxComponent: AddressesListbox,
      inputValue,
      isOptionEqualToValue,
      loading,
      onInputChange,
      renderInput,
      renderOption,
    },
    clickAwayListenerProps: {
      open,
      setOpen,
    },
  }
}
