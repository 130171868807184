import { BasePageProps } from '@xylabs/react-common'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { ListModeProvider, WebAppPage } from '@xyo-network/react-sdk'

import { DynamicBreadcrumbs } from '../../../shared'
import { PayloadRender } from '../../components'

export const PayloadRenderPage: React.FC<BasePageProps> = (props) => {
  return (
    <WebAppPage breadcrumbs={<DynamicBreadcrumbs />} {...props}>
      <ListModeProvider>
        <FlexGrowCol marginY={2} alignItems="stretch">
          <PayloadRender />
        </FlexGrowCol>
      </ListModeProvider>
    </WebAppPage>
  )
}
