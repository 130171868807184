import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import { Alert, AlertTitle, CardProps, CircularProgress, List, ListItemButton, Typography, Zoom } from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { SchemaStatsPayload } from '@xyo-network/diviner-schema-stats-model'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSchemaStatsProcessor } from '../../contexts'

ChartJS.register(ArcElement, Tooltip, Legend)

export interface SchemaListProps extends CardProps {
  schemaStats?: SchemaStatsPayload | null
}

export const SchemaList: React.FC<SchemaListProps> = ({ schemaStats, ...props }) => {
  const navigate = useNavigate()
  const { scaledCounts } = useSchemaStatsProcessor()
  const [hoveredSchema, setHoveredSchema] = useState('')

  return (
    <>
      {!schemaStats ? (
        <FlexCol minHeight={100} {...props}>
          <CircularProgress />
        </FlexCol>
      ) : (
        <List dense>
          {scaledCounts && scaledCounts.length ? (
            scaledCounts.map(({ schema, count }) => {
              return (
                <ListItemButton
                  key={schema}
                  onClick={() => navigate(`/schema/${schema}`)}
                  onMouseEnter={() => setHoveredSchema(schema)}
                  onMouseLeave={() => setHoveredSchema('')}
                >
                  <FlexRow width="100%" justifyContent="space-between">
                    <FlexRow>
                      <Typography variant="body1" ml={0.2} paddingRight={1} sx={{ textOverflow: 'ellipsis' }}>
                        {schema}
                      </Typography>
                      <Typography variant="body2" ml={0.2}>
                        ({count})
                      </Typography>
                    </FlexRow>
                    <Zoom in={hoveredSchema === schema}>
                      <ArrowRightRoundedIcon color="secondary" />
                    </Zoom>
                  </FlexRow>
                </ListItemButton>
              )
            })
          ) : (
            <Alert severity="warning">
              <AlertTitle>Unable to display top schemas.</AlertTitle>
              Unable to process schema stats.
            </Alert>
          )}
        </List>
      )}
    </>
  )
}
