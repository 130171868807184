import { SchemaFilterEvents } from './SchemaFilterEvents'

export const SchemaFilterEventBuilder = (data: string, noun: SchemaFilterEvents, namespace = 'xyo', verb = 'click') => {
  return new CustomEvent(namespace, {
    bubbles: true,
    cancelable: true,
    composed: true,
    detail: { data, noun, verb },
  })
}
