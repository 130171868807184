import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { useRenderSpinCheck } from '@xylabs/react-render-spin-check'
import { BlockTable, CardContentEx, CardEx, ErrorRender, TypographyEx, useNetwork, WebAppPage } from '@xyo-network/react-sdk'
import { useMemo } from 'react'

import { DynamicBreadcrumbs, PageCardWithAddresses, useAddressBlocks, useAddressesWithCustomNames, useSelectedAddress } from '../../shared'
import { useBlocks } from '../hooks'

export const BlockListPage: React.FC = () => {
  const name = useMemo(() => ({ name: 'BlockListPage' }), [])
  useRenderSpinCheck(name)

  const { network } = useNetwork()
  const [addresses, addressSpaceDivinerError] = useAddressesWithCustomNames()
  const { onChange, selectedAddress } = useSelectedAddress(addresses)
  const [blocks, addressHistoryDivinerError] = useAddressBlocks(selectedAddress)
  const [defaultBlocks, defaultBlocksError] = useBlocks()

  return (
    <WebAppPage title="Blocks" breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
      <ErrorRender error={addressSpaceDivinerError ?? addressHistoryDivinerError ?? defaultBlocksError}>
        <PageCardWithAddresses
          addresses={addresses}
          onAddressChange={onChange}
          selectedAddress={selectedAddress}
          subheader={<TypographyEx>Browse blocks seen on the {network?.name} network</TypographyEx>}
          title={'Blocks'}
        >
          <CardEx
            style={{
              backgroundColor: 'transparent',
              backgroundImage: 'none',
            }}
          >
            <CardContentEx variant="scrollable" sx={{ px: 0 }}>
              <FlexGrowCol alignItems="stretch">
                {selectedAddress === null ? <BlockTable blocks={defaultBlocks} /> : null}
                {selectedAddress ? blocks ? <BlockTable blocks={blocks} /> : <FlexRow minHeight={200} marginY={16} busy></FlexRow> : null}
              </FlexGrowCol>
            </CardContentEx>
          </CardEx>
        </PageCardWithAddresses>
      </ErrorRender>
    </WebAppPage>
  )
}
