import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Chip, ChipProps, CircularProgress } from '@mui/material'
import { BoundWitnessStatsQuerySchema } from '@xyo-network/diviner-boundwitness-stats-model'
import { PayloadStatsQuerySchema } from '@xyo-network/diviner-payload-stats-model'
import numeral from 'numeral'
import { ComponentType, useState } from 'react'
import { VscGraphLine, VscSymbolMethod, VscSymbolNamespace } from 'react-icons/vsc'

import { useCount } from '../../hooks'

export interface AddressStatsChipsProps extends ChipProps {
  address?: string
  format?: string
  hidePayloadStats?: boolean
  hideBoundWitnessStats?: boolean
}

export const AddressStatsChips: React.FC<AddressStatsChipsProps> = ({ address, hideBoundWitnessStats, hidePayloadStats, ...props }) => {
  const [payloadStats, payloadStatsError] = useCount(PayloadStatsQuerySchema, 'PayloadStatsDiviner', address)
  const [boundWitnessStats, boundWitnessStatsError] = useCount(BoundWitnessStatsQuerySchema, 'BoundWitnessStatsDiviner', address)
  return (
    <>
      {hidePayloadStats ? null : (
        <AddressStatChip title="Number of Payloads" error={payloadStatsError} Icon={VscSymbolNamespace} stat={payloadStats} {...props} />
      )}
      {hideBoundWitnessStats ? null : (
        <AddressStatChip
          title="Number of Bound Witnesses"
          error={boundWitnessStatsError}
          Icon={VscSymbolMethod}
          stat={boundWitnessStats}
          {...props}
        />
      )}
    </>
  )
}

export interface AddressStatChipProps extends ChipProps {
  error?: Error
  format?: string
  Icon: ComponentType
  stat?: number | null
}

const AddressStatChip: React.FC<AddressStatChipProps> = ({ error, format = '0[.]0a', Icon, stat, ...props }) => (
  <>
    {error ? (
      <ErrorRoundedIcon color="error" />
    ) : (
      <Chip avatar={stat === undefined ? <CircularProgress size={16} /> : <Icon />} label={numeral(stat).format(format)} {...props} />
    )}
  </>
)

export const AddressStatsChipOnHover: React.FC<AddressStatsChipsProps> = (props) => {
  const [hovering, setHovering] = useState(false)
  return <>{hovering ? <AddressStatsChips {...props} /> : <VscGraphLine onMouseEnter={() => setHovering(true)} />}</>
}
