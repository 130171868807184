import { WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { Route, Routes, RoutesProps } from 'react-router-dom'

import { BreadcrumbProvider } from '../../shared'
import { PayloadListPage } from '../pages'
import { PayloadRenderRoutes } from './PayloadRender'

export const PayloadRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name="Payloads" to="payload">
      <Routes {...props}>
        <Route path="/recent/" element={<PayloadListPage />} />
        <Route path="/hash/:hash/*" element={<PayloadRenderRoutes />} />
        <Route path="/:hash/*" element={<PayloadRenderRoutes />} />
        <Route path="/" element={<PayloadListPage />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
