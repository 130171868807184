import { CollapseProps, useMediaQuery, useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { CollapseToggleFlex, CollapsibleDrawer, useCollapsible } from '@xyo-network/react-sdk'

import { MenuSections } from './MenuSections'

export const ExploreMenuCollapse: React.FC<CollapseProps> = ({ collapsedSize, ...props }) => {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const { collapse } = useCollapsible()

  // collapsible drawer intended for non-mobile sizes
  if (isMedium) {
    return (
      <CollapsibleDrawer collapsedSize={collapsedSize ?? theme.spacing(4)} sx={{ alignItems: 'stretch' }} {...props}>
        <MenuSections collapse={collapse} />
        <FlexGrowCol height="100%" />
        <CollapseToggleFlex />
      </CollapsibleDrawer>
    )
  } else {
    return null
  }
}
