import { WithChildren } from '@xylabs/react-shared'
import { DefaultRenderPlugin } from '@xyo-network/react-plugins'
import { usePayload } from '@xyo-network/react-sdk'

import { RenderRouteProvider } from '../contexts'
import { DefaultPlugins } from '../plugins'

export const PayloadRenderWithRouteProvider: React.FC<WithChildren> = ({ children }) => {
  const { payload } = usePayload()

  return (
    <RenderRouteProvider defaultRender={DefaultRenderPlugin} plugins={[...DefaultPlugins]} payload={payload}>
      {children}
    </RenderRouteProvider>
  )
}
