import { useAsyncEffect } from '@xylabs/react-async-effect'
import { BoundWitness } from '@xyo-network/boundwitness'
import { BoundWitnessDivinerQueryPayload, BoundWitnessDivinerQuerySchema } from '@xyo-network/diviner-boundwitness-model'
import { TYPES } from '@xyo-network/node-core-types'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import { useState } from 'react'

export const useBlocks = (limit = 50): [BoundWitness[] | null | undefined, Error | undefined] => {
  const [blocks, setBlocks] = useState<BoundWitness[]>()
  const [blocksError, setBlocksError] = useState<Error>()
  const [diviner, divinerError] = useDivinerFromNode(TYPES.BoundWitnessDiviner.description)

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (diviner) {
        try {
          const query: BoundWitnessDivinerQueryPayload = { limit, schema: BoundWitnessDivinerQuerySchema }
          const result = (await diviner.divine([query])) as BoundWitness[]
          setBlocks(result)
          setBlocksError(undefined)
        } catch (e) {
          setBlocksError(e as Error)
          setBlocks(undefined)
        }
      }
    },
    [diviner, limit],
  )

  return [blocks, blocksError ?? divinerError]
}
