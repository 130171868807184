import { BoundWitness } from '@xyo-network/boundwitness'
import { AddressPayload } from '@xyo-network/payload-plugins'
import { ErrorRender, useAddressHistory, WebAppPage } from '@xyo-network/react-sdk'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  BreadcrumbProvider,
  DynamicBreadcrumbs,
  PageCardWithAddresses,
  SharableAddressAutoCompleteProps,
  useAddressesWithCustomNames,
  useSelectedAddress,
} from '../../shared'
import { AddressHistoryProviders, AddressHistoryRenderer } from '../components'

const autoCompleteProps: SharableAddressAutoCompleteProps = {
  showHistoryButton: false,
}

export const AddressHistoryPage = () => {
  const { address: addressParam } = useParams()
  const navigate = useNavigate()
  const [addresses, addressSpaceDivinerError] = useAddressesWithCustomNames()
  const { onChange, selectedAddress, setSelectedAddress } = useSelectedAddress(addresses, false)
  const [addressHistory, error] = useAddressHistory(addressParam)

  const onChangeWrapped = (value: AddressPayload | null) => {
    onChange(value)
    navigate(`/address/${value?.address ?? ''}`)
  }

  useEffect(() => {
    if (!addressParam) {
      setSelectedAddress(undefined)
    } else {
      setSelectedAddress(addresses?.find((item) => item.address === addressParam))
    }
  }, [addressParam, addresses, setSelectedAddress])

  return (
    <BreadcrumbProvider name={selectedAddress?.address ?? ''} to={selectedAddress?.address}>
      <WebAppPage title={`${selectedAddress?.address ?? 'Address'} History`} breadcrumbs={<DynamicBreadcrumbs />} variant="fixed">
        <ErrorRender error={error ?? addressSpaceDivinerError}>
          <PageCardWithAddresses
            addresses={addresses}
            onAddressChange={onChangeWrapped}
            selectedAddress={selectedAddress}
            autoCompleteProps={autoCompleteProps}
            title={'Browse the history of an address'}
            sx={{ height: '100%' }}
          >
            <AddressHistoryProviders>
              <AddressHistoryRenderer addressHistory={addressHistory as BoundWitness[]} />
            </AddressHistoryProviders>
          </PageCardWithAddresses>
        </ErrorRender>
      </WebAppPage>
    </BreadcrumbProvider>
  )
}
