import { useAppSettings } from '@xyo-network/react-sdk'
import { lazy } from 'react'
import { ReactJsonViewProps } from 'react-json-view'

const JsonView = lazy(() => import(/* webpackChunkName: "jsonView" */ 'react-json-view'))

export const SchemaLoadedJsonViewer: React.FC<ReactJsonViewProps> = (props) => {
  const { darkMode } = useAppSettings()

  return <JsonView theme={darkMode ? 'ashes' : 'summerfruit:inverted'} name={null} collapseStringsAfterLength={64} {...props} />
}
