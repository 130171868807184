import { styled } from '@mui/material'
import { FlexBoxProps, FlexRow } from '@xylabs/react-flexbox'
import { WithChildren } from '@xylabs/react-shared'

export const FilterRowFlex: React.FC<WithChildren<FlexBoxProps>> = ({ children, ...props }) => {
  return <FilterRowFlexStyled {...props}>{children}</FilterRowFlexStyled>
}

const FilterRowFlexStyled = styled(FlexRow)(({ theme }) =>
  theme.unstable_sx({
    columnGap: 1,
    justifyContent: 'start',
    py: 2,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  }),
)
