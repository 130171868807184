import { LinearProgress, useTheme } from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { useProvidedNode } from '@xyo-network/react-modules'
import { NodeRelationalGraph, PageCard, useCytoscapeElements, useCytoscapeOptions, useNetwork, WebAppPage } from '@xyo-network/react-sdk'

import { StatsBanner } from '../../../shared'

export const HomePageDashboard = () => {
  const theme = useTheme()
  const { network } = useNetwork()

  const [node] = useProvidedNode()
  const elements = useCytoscapeElements(node)
  const options = useCytoscapeOptions(elements)

  return (
    <WebAppPage title="Explore" paddingY={0} variant="fixed">
      <PageCard
        sx={{ height: '100%' }}
        title={`XYO - Explore ${network?.name} Node`}
        subheader={<StatsBanner flexWrap="wrap" justifyContent="start" gap={2.5} mt={2} />}
      >
        {elements?.length > 1 ? (
          <NodeRelationalGraph options={options} height="100%" />
        ) : (
          <FlexCol alignItems="stretch" height="100%">
            <LinearProgress sx={{ minHeight: theme.spacing(1) }} />
          </FlexCol>
        )}
      </PageCard>
    </WebAppPage>
  )
}
