import { WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { Route, RouteProps, Routes } from 'react-router-dom'

import { ArchivistDetailPage, ArchivistListPage } from './pages'

export const ArchivistRoutes: React.FC<RouteProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="/:archivist/*" element={<ArchivistDetailPage />} />
      <Route path="/" element={<ArchivistListPage />} />
      <Route path="*" element={<WebAppNotFoundPage />} />
    </Routes>
  )
}
