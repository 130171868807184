import CloseIcon from '@mui/icons-material/Close'
import { IconButton, SwipeableDrawer } from '@mui/material'
import { FlexBoxProps, FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { WithChildren } from '@xylabs/react-shared'
import { useArchivistFromNode } from '@xyo-network/react-modules'
import { PayloadProvider, RootStorageArchivist, useActiveBoundWitness } from '@xyo-network/react-sdk'
import { forwardRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RenderPayload } from './RenderPayload'

export interface PayloadDrawerProps extends FlexBoxProps, WithChildren {}

export const PayloadDrawer = forwardRef<HTMLDivElement, PayloadDrawerProps>(({ children, ...props }, ref) => {
  const [archivist] = useArchivistFromNode(RootStorageArchivist)
  const navigate = useNavigate()
  const { hash: payloadHashParam, address: addressParam } = useParams()
  const { activeBoundWitnessHash } = useActiveBoundWitness()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(!!payloadHashParam)
  }, [payloadHashParam])

  const navigateBack = () => {
    navigate(`/address/${addressParam}/${activeBoundWitnessHash}`)
  }

  return (
    <PayloadProvider archivist={archivist} hash={payloadHashParam}>
      <FlexGrowCol alignItems="stretch" ref={ref} {...props}>
        <SwipeableDrawer anchor={'right'} open={open} onClose={navigateBack} onOpen={() => setOpen(true)}>
          <FlexGrowCol role="presentation" justifyContent="start" p={2} rowGap={2}>
            <FlexRow alignContent="start" justifyContent="start" width="100%">
              <IconButton onClick={navigateBack}>
                <CloseIcon />
              </IconButton>
            </FlexRow>
            <RenderPayload />
          </FlexGrowCol>
        </SwipeableDrawer>
        {children}
      </FlexGrowCol>
    </PayloadProvider>
  )
})

PayloadDrawer.displayName = 'PayloadDrawer'
