import { FlexBoxProps } from '@xylabs/react-flexbox'
import { BoundWitnessStatsQuerySchema } from '@xyo-network/diviner-boundwitness-stats-model'
import { PayloadStatsQuerySchema } from '@xyo-network/diviner-payload-stats-model'
import { useSchemaList } from '@xyo-network/react-sdk'

import { useCount } from '../../hooks'
import { ArchivistStatsFlexBox } from './Stats'

export const StatsBanner: React.FC<FlexBoxProps> = (props) => {
  const [payloadCount, payloadCountError] = useCount(PayloadStatsQuerySchema, 'PayloadStatsDiviner')
  const [blockCount, blockCountError] = useCount(BoundWitnessStatsQuerySchema, 'BoundWitnessStatsDiviner')
  const [schemaList, schemaListError] = useSchemaList()

  return (
    <ArchivistStatsFlexBox
      blockCount={blockCount}
      blockCountError={blockCountError?.message}
      payloadCount={payloadCount}
      payloadCountError={payloadCountError?.message}
      schemaStats={schemaList?.schemas.length}
      schemaStatsError={schemaListError}
      {...props}
    />
  )
}
