import { Payload } from '@xyo-network/protocol'
import { useGetSchemaPayload, useNetwork } from '@xyo-network/react-sdk'
import { useEffect, useState } from 'react'

const buildNodeWebSiteUrl = (huri: string, nodeRoute: string) => {
  const params = new URLSearchParams()
  params.append('huri', encodeURIComponent(huri))
  const nodePath = `${nodeRoute}/?${params.toString()}`

  if (window.location.host.startsWith('explore')) {
    return `https://node.xyo.network/${nodePath}`
  } else if (window.location.host.startsWith('localhost')) {
    return `http://localhost:3001/${nodePath}`
  } else {
    return `https://beta.node.xyo.network/${nodePath}`
  }
}

const usePayloadActionUrls = (payload: Payload | undefined, nodeRoute: string, hash?: string) => {
  const { xyoError, notFound, schemaHuri } = useGetSchemaPayload(payload?.schema)
  const { network } = useNetwork()

  const [nodeWebSiteUrl, setNodeWebSiteUrl] = useState<string | undefined>()
  const [viewSchemaUrl, setViewSchemaUrl] = useState<string | undefined>()

  useEffect(() => {
    if (payload === null || payload === undefined) {
      setNodeWebSiteUrl(undefined)
    } else if (network && hash) {
      const huri = `${network?.nodes?.find((node) => node.type === 'archivist')?.uri}/${hash}`
      setNodeWebSiteUrl(buildNodeWebSiteUrl(huri, nodeRoute))
    }
  }, [hash, network, nodeRoute, payload])

  useEffect(() => {
    if (schemaHuri) {
      setViewSchemaUrl(`/${schemaHuri.hash}?huri=${schemaHuri.href}`)
    }
  }, [schemaHuri])

  return { nodeWebSiteUrl, notFound, viewSchemaUrl, xyoError }
}

export { usePayloadActionUrls }
