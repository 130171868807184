import { WebAppNotFoundPage } from '@xyo-network/react-sdk'
import { Route, Routes, RoutesProps } from 'react-router-dom'

import { BreadcrumbProvider } from '../shared'
import { SchemaDetailsPage, SchemaLoadedPage } from './pages'

export const SchemaRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BreadcrumbProvider name="Schema" to="schema">
      <Routes {...props}>
        <Route path="/" element={<SchemaLoadedPage />} />
        <Route path="/:schema" element={<SchemaDetailsPage />} />
        <Route path="*" element={<WebAppNotFoundPage />} />
      </Routes>
    </BreadcrumbProvider>
  )
}
