import { FormControl, MenuItem, Select, SelectProps } from '@mui/material'

export const FilterTypeSelect: React.FC<SelectProps> = (props) => {
  return (
    <FormControl fullWidth>
      <Select name="filterType" defaultValue={'equals'} {...props}>
        <MenuItem value={'equals'}>equals</MenuItem>
      </Select>
    </FormControl>
  )
}
