import { PayloadDivinerQueryPayload, PayloadDivinerQuerySchema } from '@xyo-network/diviner-payload-model'
import { useMemo } from 'react'

// eslint-disable-next-line deprecation/deprecation
export const usePayloadFindFilter = ({ limit, schemas, offset, address }: Omit<PayloadDivinerQueryPayload, 'schema'>) => {
  const filter = useMemo(() => {
    const filterBase: PayloadDivinerQueryPayload = { address, limit, schema: PayloadDivinerQuerySchema }
    schemas ? (filterBase.schemas = schemas) : null
    offset !== undefined ? (filterBase.offset = offset) : null
    return filterBase
  }, [address, limit, offset, schemas])

  return filter
}
