import { useAsyncEffect } from '@xylabs/react-async-effect'
import { ArchivistInstance } from '@xyo-network/archivist'
import { useEvent } from '@xyo-network/react-sdk'
import { useRef, useState } from 'react'

import { useFavoriteAddresses } from '../contexts'
import { HandleAddressFavorite } from '../lib'

export const useForwardFavoriteEvents = (favoritesArchivist?: ArchivistInstance, element: HTMLElement = document.body) => {
  const [handleAddressFavorite, setHandleAddressFavorite] = useState<HandleAddressFavorite>()
  const ref = useRef<HTMLElement>(element)
  const { addFavoriteAddresses, removeFavoriteAddresses } = useFavoriteAddresses(true)

  useFavorite(handleAddressFavorite)

  useEvent((noun, verb, data) => {
    if (!favoritesArchivist) {
      console.error('No favoriteArchivist was found')
      return
    }
    if (favoritesArchivist && verb === 'favorite') {
      setHandleAddressFavorite(new HandleAddressFavorite(favoritesArchivist, noun, verb, data, addFavoriteAddresses, removeFavoriteAddresses))
    }
  }, ref)
}

export const useFavorite = (handleAddressFavorite?: HandleAddressFavorite) => {
  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (handleAddressFavorite) {
        await handleAddressFavorite.applyFavoriteEvent()
      }
    },
    [handleAddressFavorite],
  )
}
