import { Card, CardHeader, CardProps, LinearProgress, Typography } from '@mui/material'
import { FlexBoxProps, FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { CardContentEx, ErrorRender, PayloadTable, useSchema } from '@xyo-network/react-sdk'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { SchemaFilterChip, useSchemaStatsFilter } from '../../../schema'
import { PayloadListProvider, useNewPayloads, usePayloadList } from '../../contexts'

export const PayloadListCardHeaderFlex: React.FC<FlexBoxProps> = ({ ...props }) => (
  <FlexRow justifyContent="start" flexWrap="wrap" columnGap={1} {...props}>
    <Typography variant="h5">Recent Payloads</Typography>
    <SchemaFilterChip />
  </FlexRow>
)

const useFindAddress = () => {
  const { address: addressFromParams } = useParams()
  const [params] = useSearchParams()
  return addressFromParams ?? params.get('address') ?? undefined
}

const useFindSchema = () => {
  const { schema: schemaFromContext } = useSchema()
  const { schema: schemaFromParams } = useParams()
  const { schemaFilter: schemaFromFilterContext } = useSchemaStatsFilter()
  return schemaFromFilterContext ?? schemaFromParams ?? schemaFromContext
}

export const PayloadListCard: React.FC<CardProps> = (props) => {
  const schema = useFindSchema()
  const address = useFindAddress()
  const [error, setError] = useState<Error>()

  const { loading, scrollRef, scrollToTop, totalPayloads, count } = usePayloadList()

  const { xyoError } = useNewPayloads(schema, address)

  useEffect(() => {
    setError(xyoError)
  }, [xyoError])

  return (
    <Card {...props}>
      <CardHeader title={<PayloadListCardHeaderFlex />} />
      <CardContentEx variant="scrollable" style={{ height: '100%', justifyContent: 'start' }} scrollToTop={scrollToTop} ref={scrollRef}>
        <ErrorRender error={error} onCancel={() => setError(undefined)}>
          {!totalPayloads ? (
            <FlexGrowCol minHeight="100%">
              <LinearProgress />
            </FlexGrowCol>
          ) : (
            <PayloadTable ref={scrollRef} maxSchemaDepth={2} exploreDomain="" loading={loading} count={count} payloads={totalPayloads} />
          )}
        </ErrorRender>
      </CardContentEx>
    </Card>
  )
}

export const PayloadListCardWithProvider: React.FC<CardProps> = (props) => (
  <PayloadListProvider>
    <PayloadListCard {...props} />
  </PayloadListProvider>
)
