import { WithChildren } from '@xylabs/react-shared'
import { useEvent } from '@xyo-network/react-sdk'
import { RefObject, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SchemaFilterEvents } from '../../../shared'
import { SchemaStatsFilterContext } from './Context'

interface SchemaFilterStatsProviderProps extends WithChildren {
  schemaFilterRef?: RefObject<HTMLDivElement>
}

export const SchemaFilterStatsProvider: React.FC<SchemaFilterStatsProviderProps> = ({ children, schemaFilterRef }) => {
  const navigate = useNavigate()
  const [schemaFilter, setSchemaFilter] = useState<string | null>()
  const [schemaFilterBgColor, setSchemaFilterBgColor] = useState<string>()

  const [, schemaFilterDispatch] = useEvent<HTMLDivElement, SchemaFilterEvents>((noun, _verb, data) => {
    switch (noun) {
      case 'schemaFilter': {
        setSchemaFilter(data)
        break
      }
      case 'schemaFilterBgColor': {
        setSchemaFilterBgColor(data)
        break
      }
      case 'legendItem': {
        navigate(`/schema/${data}`)
        break
      }
      case 'clearSchemaFilter': {
        setSchemaFilter(undefined)
        setSchemaFilterBgColor(undefined)
        break
      }
    }
  }, schemaFilterRef)

  return (
    <SchemaStatsFilterContext.Provider
      value={{
        provided: true,
        schemaFilter,
        schemaFilterBgColor,
        schemaFilterDispatch,
        setSchemaFilter,
      }}
    >
      {children}
    </SchemaStatsFilterContext.Provider>
  )
}
