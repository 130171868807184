import { Breadcrumbs, BreadcrumbsProps } from '@mui/material'
import { FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { ShareButton } from '@xyo-network/react-share'
import { To } from 'react-router-dom'

import { BreadcrumbProvider, useBreadcrumbs } from '../../contexts'

export interface DynamicBreadcrumbs extends BreadcrumbsProps {
  name?: string
  to?: To
  href?: string
}

export const DynamicBreadcrumbs: React.FC<DynamicBreadcrumbs> = ({ name, to, href, children, ...props }) => {
  const { breadcrumbs = [], root = '/' } = useBreadcrumbs()

  const resolvedTo = to ? `${root}${to}/` : root

  const resolvedBreadcrumbs = [...breadcrumbs, ...(name ? [{ href, name, to: resolvedTo }] : [])]

  const Inner: React.FC = () => {
    return (
      <Breadcrumbs {...props}>
        {resolvedBreadcrumbs.map((breadcrumb, index) => (
          <LinkEx key={index} color="secondary" to={breadcrumb.to} href={breadcrumb.href}>
            {breadcrumb.name}
          </LinkEx>
        ))}
      </Breadcrumbs>
    )
  }

  return (
    <FlexRow gap={1} flexWrap="wrap">
      {name ? (
        <BreadcrumbProvider name={name} to={to} href={href}>
          <Inner />
          {children}
        </BreadcrumbProvider>
      ) : (
        <Inner />
      )}
      <ShareButton />
    </FlexRow>
  )
}
