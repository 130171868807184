import { Button } from '@mui/material'
import { ButtonExProps } from '@xylabs/react-button'
import { forwardRef } from 'react'

export interface SeeHistoryButtonProps extends ButtonExProps {
  value?: string
}

export const AddressHistoryButton = forwardRef<HTMLButtonElement, SeeHistoryButtonProps>(({ value, ...props }, ref) => {
  return (
    <Button ref={ref} variant="contained" disabled={!value} {...props}>
      History
    </Button>
  )
})

AddressHistoryButton.displayName = 'AddressHistoryButton'
