import { BarElement, CategoryScale, Chart as ChartJS, ChartData, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { MutableRefObject } from 'react'
import { Bar } from 'react-chartjs-2'

import { SchemaChartComponentProps } from './lib'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const SchemaBarChart: React.FC<SchemaChartComponentProps> = ({ chartRef, chartData, onClick }) => {
  const ref = chartRef as MutableRefObject<ChartJS<'bar'>>
  const data = chartData as ChartData<'bar'>

  return (
    <Bar
      ref={ref}
      data={data}
      onClick={onClick}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Click to Filter by schema',
          },
        },
        responsive: true,
      }}
      height="100%"
    />
  )
}
