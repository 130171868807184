import { WithChildren } from '@xylabs/react-shared'
import { assertEx } from '@xylabs/sdk-js'
import { Payload } from '@xyo-network/payload'
import { PayloadRenderPlugin } from '@xyo-network/react-sdk'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { WithConfig } from '../../plugins'
import { RenderContext } from './Context'

export interface RenderRouteProviderProps {
  defaultRender: PayloadRenderPlugin
  plugins?: WithConfig<PayloadRenderPlugin>[]
  payload?: Payload | null
}

export const RenderRouteProvider: React.FC<WithChildren<RenderRouteProviderProps>> = ({ plugins: pluginsProp, defaultRender, children, payload }) => {
  const { render, hash } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const [schemaDefaultRender] = useState(new Map<string, WithConfig<PayloadRenderPlugin>>())

  const plugins: WithConfig<PayloadRenderPlugin>[] = []

  const resolvedRender = render ?? schemaDefaultRender.get(payload?.schema ?? '')?.slug

  const register = (config: WithConfig<PayloadRenderPlugin>, defaultForSchema?: string[]) => {
    plugins.push(config)
    defaultForSchema?.forEach((schema) => {
      schemaDefaultRender.set(schema, config)
    })
  }

  pluginsProp?.forEach((plugin) => register(plugin))

  const buildRenderPath = (render: string) => {
    const sourceHash = assertEx(hash, 'Trying to build a render path without a defined hash')
    const pathParts = location.pathname.split('/')
    const newParts: string[] = []
    let index = 0
    while (index < pathParts.length && pathParts[index] !== hash) {
      newParts.push(pathParts[index])
      index++
    }
    newParts.push(sourceHash)
    newParts.push(render)

    return newParts.join('/')
  }

  const setRender = (render: string) => {
    navigate(buildRenderPath(render))
  }

  const plugin = plugins.find((plugin) => plugin.slug === resolvedRender)

  const canRender = plugins
    .map((plugin) => (payload ? (plugin.canRender(payload) ? plugin : null) : null))
    .filter((value) => value !== null) as WithConfig<PayloadRenderPlugin>[]
  return (
    <RenderContext.Provider
      value={{
        canRender,
        plugin: plugin ?? canRender[0] ?? defaultRender,
        provided: true,
        register,
        render: resolvedRender,
        setRender,
      }}
    >
      {children}
    </RenderContext.Provider>
  )
}
