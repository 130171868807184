function bezier4pt(t: number, ...points: number[]): number {
  const [P1, P2, P3, P4] = points
  const P = Math.pow(1 - t, 3) * P1 + 3 * Math.pow(1 - t, 2) * t * P2 + 3 * (1 - t) * Math.pow(t, 2) * P3 + Math.pow(t, 3) * P4
  return P
}

/**
 * Calculate the scaled value of an item within two ranges, optionally against a curve
 * @param item Value to scale
 * @param itemRange Min and max value range for item
 * @param controlRange Min and max value range to scale to the item range
 * @param [bezierCurve] Four number array of the benzier curve to adjust the scale
 * @returns values
 */
function scaleValue(item: number, itemRange: number[], controlRange: number[], bezierCurve?: number[]) {
  const [itemMin, itemMax] = itemRange
  const [controlRangeMin, controlRangeMax] = controlRange || []

  const itemSpan = itemMax - itemMin
  const controlRangeSpan = controlRangeMax - controlRangeMin

  if (itemSpan === 0) {
    return { percent: 100, scaledItem: 1 }
  }

  let percent = (item - itemMin) / itemSpan
  if (bezierCurve) {
    percent = bezier4pt(percent, ...bezierCurve)
  }
  const scaledItem = percent * controlRangeSpan + controlRangeMin

  return { percent, scaledItem }
}

export { scaleValue }
