import { WithChildren } from '@xylabs/react-shared'
import { useCallback, useState } from 'react'

import { FavoriteAddressPayload } from '../lib'
import { FavoriteAddressesContext } from './FavoriteAddressesContext'
import { AddressAliases, FavoriteAddressesState } from './FavoriteAddressState'

export type FavoriteAddressesProviderProps = WithChildren

export const FavoriteAddressesProvider: React.FC<FavoriteAddressesProviderProps> = ({ children }) => {
  const [addressNames, setAddressAliases] = useState<AddressAliases>({})

  const addFavoriteAddresses: FavoriteAddressesState['addFavoriteAddresses'] = useCallback((favoriteAddresses: FavoriteAddressPayload[]) => {
    setAddressAliases((previous) => {
      favoriteAddresses?.forEach((favoriteAddress) => {
        previous[favoriteAddress.address] = favoriteAddress.name
      })
      return { ...previous }
    })
  }, [])

  const removeFavoriteAddresses: FavoriteAddressesState['removeFavoriteAddresses'] = useCallback((favoriteAddresses: FavoriteAddressPayload[]) => {
    setAddressAliases((previous) => {
      let changed = false
      favoriteAddresses?.map((favoriteAddress) => {
        if (favoriteAddress.address in previous) {
          changed = true
          delete previous[favoriteAddress.address]
          return {
            ...previous,
          }
        } else {
          return previous
        }
      })
      return changed ? { ...previous } : previous
    })
  }, [])

  return (
    <FavoriteAddressesContext.Provider
      value={{
        addFavoriteAddresses,
        addressNames,
        provided: true,
        removeFavoriteAddresses,
      }}
    >
      {children}
    </FavoriteAddressesContext.Provider>
  )
}
