import { Theme, useMediaQuery } from '@mui/material'
import { FlexCol, FlexGrowRow } from '@xylabs/react-flexbox'
import { useRenderSpinCheck } from '@xylabs/react-render-spin-check'
import { CardContentEx, ErrorRender, TypographyEx, useNetwork, WebAppPage } from '@xyo-network/react-sdk'
import { useMemo, useRef } from 'react'

import { SchemaChartProviders, SchemaFilterFlex, SchemaListCard } from '../../../schema'
import { DynamicBreadcrumbs, PageCardWithAddresses, useAddressesWithCustomNames, useSelectedAddress } from '../../../shared'
import { PayloadListCardWithProvider } from '../../components'

export const PayloadListPage: React.FC = () => {
  const name = useMemo(() => ({ name: 'PayloadListPage' }), [])
  useRenderSpinCheck(name)

  const { network } = useNetwork()
  const [addresses, addressSpaceDivinerError] = useAddressesWithCustomNames()
  const { onChange, selectedAddress } = useSelectedAddress(addresses)

  const schemaFilterRef = useRef<HTMLDivElement>(null)

  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const flexDirection = isMedium ? 'column' : 'row'
  const overflow = isMedium ? 'auto' : 'hidden'

  return (
    <WebAppPage title="Payloads" breadcrumbs={<DynamicBreadcrumbs />} mobileScrollingBreakpoint={'md'} variant="fixed">
      <ErrorRender error={addressSpaceDivinerError}>
        <PageCardWithAddresses
          addresses={addresses}
          onAddressChange={onChange}
          selectedAddress={selectedAddress ?? null}
          ref={schemaFilterRef}
          subheader={<TypographyEx>Browse payloads seen on the {network?.name} network</TypographyEx>}
          title={'Payloads'}
        >
          <SchemaChartProviders schemaFilterRef={schemaFilterRef}>
            <SchemaFilterFlex paper mx={2} />
            <CardContentEx variant="scrollable" sx={{ display: 'flex', flexDirection, flexGrow: 1, gap: 1, height: '100%', pt: 0 }}>
              <FlexGrowRow id="column-wrapper" gap={2} overflow={overflow} flexDirection={flexDirection} alignItems="stretch">
                {selectedAddress ? (
                  <FlexCol id="schema-list-card-wrapper" justifyContent="start" alignItems="stretch" gap={2} width={isMedium ? 'auto' : '33.33%'}>
                    <SchemaListCard sx={{ background: 'none' }} cardContentProps={{ sx: { minHeight: '400px' } }} />
                  </FlexCol>
                ) : null}
                <PayloadListCardWithProvider sx={{ background: 'none', border: 'none', flexGrow: 3, justifyContent: 'start', overflow }} />
              </FlexGrowRow>
            </CardContentEx>
          </SchemaChartProviders>
        </PageCardWithAddresses>
      </ErrorRender>
    </WebAppPage>
  )
}
