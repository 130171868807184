import { alpha, Palette, useTheme } from '@mui/material'

type PaletteColors = 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThemeOverrides = (): any => {
  const theme = useTheme()
  const palette = theme.palette as Pick<Palette, PaletteColors>

  const baseOverrideBuilder = (color: PaletteColors) => ({
    backgroundColor: alpha(palette[color].light, 0.2),
    border: `1px solid ${theme.palette[color].light}`,
    color: theme.palette[color].main,
  })

  const badgeOverrideBuilder = (color: PaletteColors) => ({
    ...baseOverrideBuilder(color),
    color: theme.palette.text.primary,
  })

  const chipOverrideBuilder = (color: PaletteColors) => ({
    ...baseOverrideBuilder(color),
  })

  return {
    components: {
      MuiBadge: {
        styleOverrides: {
          colorError: badgeOverrideBuilder('error'),
          colorInfo: badgeOverrideBuilder('info'),
          colorPrimary: badgeOverrideBuilder('primary'),
          colorSecondary: badgeOverrideBuilder('secondary'),
          colorSuccess: badgeOverrideBuilder('success'),
          colorWarning: badgeOverrideBuilder('warning'),
        },
      },
      MuiChip: {
        styleOverrides: {
          colorError: chipOverrideBuilder('error'),
          colorInfo: chipOverrideBuilder('info'),
          colorPrimary: chipOverrideBuilder('primary'),
          colorSecondary: chipOverrideBuilder('secondary'),
          colorSuccess: chipOverrideBuilder('success'),
          colorWarning: chipOverrideBuilder('warning'),
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            maxWidth: 'xl',
          },
        },
      },
    },
  }
}
