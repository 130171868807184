import { useRenderSpinCheck } from '@xylabs/react-render-spin-check'
import { BoundWitness } from '@xyo-network/protocol'
import { useArchivistFromNode, useArchivistGet } from '@xyo-network/react-modules'
import { BoundWitnessDetailsBox } from '@xyo-network/react-plugins'
import { ErrorRender, LoadResult, RootStorageArchivist, useEvent, WebAppPage } from '@xyo-network/react-sdk'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { DynamicBreadcrumbs } from '../../shared'

export const BlockDetailsPage: React.FC = () => {
  const { hash } = useParams()
  const navigate = useNavigate()

  const name = useMemo(() => ({ name: 'BlockDetailsPage' }), [])
  useRenderSpinCheck(name)

  const hashes = useMemo(() => (hash ? [hash] : undefined), [hash])

  const [archivist] = useArchivistFromNode(RootStorageArchivist)

  const [blocks, xyoError] = useArchivistGet(archivist, hashes)

  const [ref] = useEvent<HTMLDivElement>((noun, verb, data) => {
    switch (noun) {
      case 'boundwitness': {
        switch (verb) {
          case 'click':
            navigate(`/block/hash/${data}`)
            break
        }
        break
      }
      case 'payload': {
        switch (verb) {
          case 'click':
            navigate(`/payload/hash/${data}`)
            break
        }
        break
      }
      case 'address': {
        switch (verb) {
          case 'click':
            navigate(`/address/${data}`)
            break
        }
        break
      }
    }
  })

  const block = blocks?.[0] as BoundWitness
  const notFound = blocks?.length === 0

  return (
    <WebAppPage title={hash} breadcrumbs={<DynamicBreadcrumbs />}>
      <LoadResult<BoundWitness> {...{ notFound, searchResult: block, xyoError }}>
        <ErrorRender error={xyoError}>{block ? <BoundWitnessDetailsBox ref={ref} marginY={2} px={1} payload={block} /> : null}</ErrorRender>
      </LoadResult>
    </WebAppPage>
  )
}
