import { useAsyncEffect } from '@xylabs/react-async-effect'
import { FlexBoxProps, FlexCol } from '@xylabs/react-flexbox'
import { PayloadHasher } from '@xyo-network/protocol'
import { ErrorRender, useSchemaDefinitions } from '@xyo-network/react-sdk'
import { SchemaPayload, SchemaSchema } from '@xyo-network/schema-payload-plugin'
import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useMemo, useState } from 'react'

import { useSchemaList } from './hooks'
import { SchemaLoadedViewer } from './SchemaLoadedViewer'

type HashSchemaPayloadTuple = [string, SchemaPayload]

const generateSchema = (name?: string) => ({
  definition: {
    $id: name,
    $schema: 'http://json-schema.org/draft-07/schema#',
    additionalProperties: true,
  },
  schema: SchemaSchema,
})

export interface SchemaLoadedListProps extends FlexBoxProps {
  address?: string
}

export const SchemaLoadedList: React.FC<SchemaLoadedListProps> = ({ address, ...props }) => {
  const [schemaList, schemaListError] = useSchemaList(address)

  const mappedSchemaListNames = useMemo(() => (schemaList ? schemaList.schemas.map((item) => ({ name: item })) : undefined), [schemaList])
  const resolvedSchemaPayloads = useSchemaDefinitions(mappedSchemaListNames)

  const [schemaPayloadsList, setSchemaPayloadList] = useState<SchemaPayload[]>()
  const [hashedSchemaPayloadsList, setHashedSchemaPayloadsList] = useState<HashSchemaPayloadTuple[]>([])

  // Set initial list of payloads from SchemaListDiviner
  useEffect(() => {
    if (mappedSchemaListNames) {
      const tempList: SchemaPayload[] = []
      mappedSchemaListNames.forEach(({ name }) => {
        const schema = generateSchema(name)
        schema.definition.$id = name
        tempList.push(schema)
      })
      setSchemaPayloadList(tempList)
    }
  }, [mappedSchemaListNames])

  // Replace schema payloads with new ones found via domain configs
  useEffect(() => {
    if (resolvedSchemaPayloads) {
      // const payloadSchema = resolvedSchemaPayloads?.find((payload) => payload.definition.$id === 'network.xyo.payload')
      setSchemaPayloadList(
        (existingSchemaPayloadList) =>
          existingSchemaPayloadList?.map((existingSchemaPayload) => {
            const name = existingSchemaPayload.definition.$id
            const matchedResolvedSchemaPayload = resolvedSchemaPayloads?.find((payload) => payload.definition.$id === name)
            return matchedResolvedSchemaPayload ? cloneDeep(matchedResolvedSchemaPayload) : existingSchemaPayload
          }),
      )
    }
  }, [mappedSchemaListNames, resolvedSchemaPayloads])

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async () => {
      if (schemaPayloadsList?.length) {
        const list = await Promise.all(
          schemaPayloadsList.map<Promise<HashSchemaPayloadTuple>>(async (schemaPayload) => {
            const hash = await PayloadHasher.hashAsync(schemaPayload)
            return [hash, schemaPayload]
          }),
        )
        setHashedSchemaPayloadsList(list)
      }
    },
    [schemaPayloadsList],
  )

  return (
    <ErrorRender error={schemaListError}>
      {hashedSchemaPayloadsList.length ? (
        <FlexCol {...props}>
          {hashedSchemaPayloadsList.map(([hash, schemaPayload]) => {
            return <SchemaLoadedViewer key={hash} alignItems="stretch" schema={schemaPayload} />
          })}
        </FlexCol>
      ) : (
        <FlexCol minHeight={200} busy />
      )}
    </ErrorRender>
  )
}
