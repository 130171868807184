import { useAsyncEffect } from '@xylabs/react-async-effect'
import { TYPES } from '@xyo-network/node-core-types'
import { AddressPayload } from '@xyo-network/payload-plugins'
import { useDivinerFromNode } from '@xyo-network/react-modules'
import compact from 'lodash/compact'
import { useEffect, useState } from 'react'

import { useFavoriteAddresses } from '../../favorites'
import { AddressWithFavorite } from '../types'

export const useAddresses = (): [AddressPayload[] | undefined, Error | undefined] => {
  const [addresses, setAddresses] = useState<AddressPayload[]>()
  const [error, setError] = useState<Error>()
  const [addressSpaceDiviner, addressSpaceDivinerError] = useDivinerFromNode(TYPES.AddressSpaceDiviner.description)

  useEffect(() => {
    // proactively delete stale addresses once diviner changes
    setAddresses([])
  }, [addressSpaceDiviner])

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (mounted) => {
      try {
        const addresses = compact(await addressSpaceDiviner?.divine()) as AddressPayload[]
        setAddresses(addresses)
      } catch (ex) {
        if (mounted()) {
          setError(ex as Error)
        }
      }
    },
    [addressSpaceDiviner],
  )

  return [addresses, error ?? addressSpaceDivinerError]
}

export const useAddressesWithCustomNames = (): [AddressWithFavorite[] | undefined, Error | undefined] => {
  const [addresses, addressesError] = useAddresses()
  const { addressNames } = useFavoriteAddresses(true)
  const [addressesWithFavorites, setAddressWithFavorites] = useState<AddressWithFavorite[]>()

  useEffect(() => {
    if (addresses && addressNames) {
      setAddressWithFavorites(
        addresses.map((address) => {
          const customName = addressNames[address.address]
          return {
            ...address,
            favorite: address.address in addressNames,
            name: customName,
          }
        }),
      )
    }
  }, [addresses, addressNames])

  return [addressesWithFavorites, addressesError]
}
