import { FormControl, MenuItem, Select, SelectProps } from '@mui/material'

export const FilterNameSelect: React.FC<SelectProps> = (props) => {
  return (
    <FormControl fullWidth>
      <Select name="filterName" defaultValue={'SchemaName'} {...props}>
        <MenuItem value={'SchemaName'}>Schema Name</MenuItem>
      </Select>
    </FormControl>
  )
}
