import { WithChildren } from '@xylabs/react-shared'
import { SchemaStatsPayload } from '@xyo-network/diviner-schema-stats-model'
import { useEvent } from '@xyo-network/react-sdk'
import { RefObject, useEffect, useState } from 'react'

import { SchemaFilterEvents } from '../../../shared'
import { ScaledStats } from '../lib'
import { SchemaStatsProcessorContext } from './Context'
import { ScaledStatsGenerator } from './ScaledStatsGenerator'

type OffsetChange = 'up' | 'down'

interface SchemaStatsProcessorProviderProps extends WithChildren {
  schemaStats?: SchemaStatsPayload | null
  scaledCount?: number
  schemaFilterRef?: RefObject<HTMLDivElement>
}

export const SchemaStatsProcessorProvider: React.FC<SchemaStatsProcessorProviderProps> = ({
  children,
  scaledCount = 5,
  schemaStats,
  schemaFilterRef,
}) => {
  const [scaledStatsGenerator, setScaledStatsGenerator] = useState<ScaledStatsGenerator>()
  const [scaledStats, setScaledStats] = useState<ScaledStats>()

  useEffect(() => {
    if (scaledCount && schemaStats) {
      const scaledStatsGenerator = new ScaledStatsGenerator(schemaStats, scaledCount, setScaledStats)
      scaledStatsGenerator.generateInitialCounts()
      setScaledStatsGenerator(scaledStatsGenerator)
    }
  }, [scaledCount, schemaStats])

  useEvent<HTMLDivElement, SchemaFilterEvents, OffsetChange>((noun, _, data) => {
    switch (noun) {
      case 'browseRemaining': {
        if (['up', 'down'].includes(data ?? '')) {
          scaledStatsGenerator?.changeOffset(data as OffsetChange)
        } else {
          console.warn(`${data} is not a recognized event data value`)
        }
        break
      }
      case 'schemaFilter': {
        scaledStatsGenerator?.filterBySchema(data)
        break
      }
      case 'clearSchemaFilter': {
        scaledStatsGenerator?.filterBySchema()
        break
      }
    }
  }, schemaFilterRef)

  return (
    <SchemaStatsProcessorContext.Provider
      value={{
        allScaledCounts: scaledStats?.allScaledCounts,
        offsetEnd: scaledStats?.offsetEnd,
        offsetStart: scaledStats?.offsetStart,
        otherScaledCountsSum: scaledStats?.otherScaledCountsSum,
        provided: true,
        scaledCounts: scaledStats?.scaledCounts,
        total: scaledStats?.total,
      }}
    >
      {children}
    </SchemaStatsProcessorContext.Provider>
  )
}
